import React, { ComponentProps, memo, useEffect, useState } from 'react';
import { COLORS } from '@scrum/shared-lib';
import { Spinner } from '@components/spinner/Spinner';
import { Icon } from '@components/app-icon/Icon';
import './styles.sass';

type TSpinnerProps = ComponentProps<typeof Spinner>;
type TIconProps = ComponentProps<typeof Icon>;

interface IProps extends TSpinnerProps {
  text?: string;
  icon?: TIconProps['icon'];
  iconColor?: TIconProps['color'];
  iconSize?: TIconProps['size'];
  reverse?: boolean;
  width?: number | string;
  isLoading?: boolean;
  isDisabled?: boolean;
  textColor?: string;
  align?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderRadius?: number | string;
  onClick?: () => void;
  backgroundColor?: string;
  hoverColor?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
  children?: React.ReactNode;
}

export const AppButton = memo(
  ({
    text,
    icon,
    iconSize = 20,
    iconColor,
    textColor = COLORS.WHITE,
    backgroundColor = COLORS.GRAY_10,
    hoverColor = COLORS.GRAY_9,
    width,
    isDisabled = false,
    isLoading = false,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    onClick,
    borderRadius,
    reverse,
    paddingHorizontal,
    paddingVertical,
  }: IProps) => {
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
      if (isDisabled) {
        setHovered(false);
      }
    }, [isDisabled]);

    const color = hovered ? hoverColor : backgroundColor;

    return (
      <button
        className={`app-button ${isDisabled ? '--disabled' : ''} ${reverse ? '--reverse' : ''}`}
        disabled={isDisabled || isLoading}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        onClick={onClick}
        style={{
          backgroundColor: color,
          marginBottom,
          borderRadius,
          marginLeft,
          marginRight,
          marginTop,
          width,
          padding: `${paddingVertical || 0}px ${paddingHorizontal || 10}px`,
        }}
      >
        {text && (
          <span
            style={{
              color: textColor,
              margin: `0 ${reverse ? '0px' : '10px'} 0 ${reverse ? '10px' : '0'}`,
            }}
          >
            {text}
          </span>
        )}
        {isLoading ? (
          <Spinner size={25} />
        ) : (
          icon && (
            <Icon
              icon={icon}
              size={iconSize}
              color={isDisabled ? iconColor || textColor : iconColor}
            />
          )
        )}
      </button>
    );
  },
);
