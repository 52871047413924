export const Leader = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_286_2852)">
        <path
          d="M12.6667 14.6666H3.33336V13.3333H12.6667V14.6666ZM11.3334 6.66659C10.3867 6.66659 9.50669 7.17992 9.03336 7.99992H8.66669V4.66659H10.6667V3.33325H8.66669V1.33325H7.33336V3.33325H5.33336V4.66659H7.33336V7.99992H6.96669C6.23336 6.72659 4.60002 6.28659 3.33336 7.02659C2.71928 7.37783 2.26932 7.95799 2.08192 8.64015C1.89451 9.3223 1.98492 10.0509 2.33336 10.6666C2.82669 11.4933 3.71336 11.9999 4.66669 11.9999H11.3334C12.0406 11.9999 12.7189 11.719 13.219 11.2189C13.7191 10.7188 14 10.0405 14 9.33325C14 8.62601 13.7191 7.94773 13.219 7.44763C12.7189 6.94754 12.0406 6.66659 11.3334 6.66659Z"
          fill="#FEDD2C"
        />
      </g>
      <defs>
        <clipPath id="clip0_286_2852">
          <rect width="16" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
