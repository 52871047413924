import { memo, useCallback } from 'react';
import { useTypedSelector } from '@hooks/typed-selector';
import { UserItem } from '@pages/room/user-bar/UserItem';
import { ETaskStage, ITask, IVote } from '@scrum/shared-lib';
import { useTransition, animated } from 'react-spring';
import './styles.sass';

interface IProps {
  stage: ITask['stage'] | undefined;
  votes: { [userId: string]: IVote } | null;
}

export const UserBar = memo(({ stage, votes }: IProps) => {
  const users = useTypedSelector(({ scrumRoom }) => scrumRoom.users);
  const useAnonymousVote = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useAnonymousVote,
  );
  const currentUserId = useTypedSelector(({ scrumRoom }) => scrumRoom?.currentUser?.id || '');
  const isLeading = useTypedSelector(({ scrumRoom }) => scrumRoom?.currentUser?.role === 'leading');
  const canShowResult = useAnonymousVote ? stage === ETaskStage.RESULTS : true;

  const getUserVote = useCallback(
    (userId: string) => {
      if (votes) {
        const value = votes[userId]?.value;
        return typeof value === 'number' ? value : '';
      }
    },
    [votes],
  );

  const transitions = useTransition(users, {
    enter: [{ transform: 'scale(1)' }, { transform: 'none', immediate: true }],
    from: { transform: 'scale(0)' },
    leave: [{ transform: 'scale(1)', immediate: true }, { transform: 'scale(0)' }],
    sort: ({ fname: a }, { fname: b }) => a.localeCompare(b),
    trail: 30,
    keys: item => item.id,
  });

  return (
    <div className="users">
      {transitions((styles, user) => (
        <animated.div style={styles}>
          <UserItem
            {...user}
            {...{ canShowResult, isLeading }}
            isCurrentUser={currentUserId === user.id}
            isVoting={stage === ETaskStage.VOTING}
            voteValue={getUserVote(user.id)}
          />
        </animated.div>
      ))}
    </div>
  );
});
