export const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    BLACK_1: '#141414',
    GRAY_5: '#D9D9D9',
    GRAY_6: '#BFBFBF',
    GRAY_7: '#8C8C8C',
    GRAY_8: '#595959',
    GRAY_9: '#434343',
    GRAY_10: '#262626',
    YELLOW: '#FEDD2C',
    YELLOW_2: '#D99236',
    GREEN_1: '#27ae60',
    GREEN_2: '#255A4A',
    GREEN_6: '#66BB6A',
    OCEAN: '#0F5767',
    RED: '#FA541C',
    TRANSPARENT: 'transparent',
    randomHexColor: () => {
        let color = '#';
        for (let i = 0; i < 3; i++)
            color += ('0' + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
        return color;
    },
    convertHEXtoRGBA: (hex: string, alpha: number) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    },
};

export const ACCENT_COLORS: string[] = ['#255A4A', '#253A5A', '#4F255A', '#5A2525', '#40909B', '#FEDD2C', '#D99236'];
