import './styles.sass';

interface IProps {
  text?: string;
  isChecked: boolean;
  onClick: () => void;
}

export const Checkbox = ({ text, isChecked, onClick }: IProps) => {
  return (
    <label>
      <input checked={isChecked} type="radio" onChange={onClick} /> {text}
    </label>
  );
};
