import './styles.sass';
import Lottie from 'react-lottie';

export const EmptyPage = () => {
  return (
    <div className="empty-page">
      <Lottie
        style={{ width: '40%', height: '40%' }}
        options={{ animationData: require('../../assets/animations/404.json'), loop: true }}
      />
    </div>
  );
};
