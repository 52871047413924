import { memo, PureComponent } from 'react';
import { SocketService } from '@services/AppSocket';
import { COLORS, IVote } from '@scrum/shared-lib';
import { Transition, animated, config } from 'react-spring';
import './styles.sass';

interface IProps {
  accentColor: string;
  myVote: IVote | null | undefined;
  currentTaskId: string;
  currentUserId: string;
}

const cards = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 0];
const defaultColor = COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.8);

export class Cards extends PureComponent<IProps> {
  private setVote = (value: number | null) => {
    const { myVote, currentTaskId, currentUserId } = this.props;
    if (value === null && myVote) {
      SocketService.send('removeVote', {
        taskId: currentTaskId,
        voteId: myVote.id,
        userId: currentUserId,
      });
    } else if (value !== null) {
      SocketService.send('addVote', { value, taskId: currentTaskId });
    }
  };

  render() {
    const { myVote, accentColor } = this.props;

    return (
      <div className="cards">
        <Transition
          items={cards}
          trail={55}
          config={config.gentle}
          enter={{
            from: { transform: 'translateX(-30px) scale(0.3)', opacity: 0 },
            to: { transform: 'translateX(0px) scale(1)', opacity: 1 },
          }}
        >
          {(props, item) => (
            <animated.div style={props}>
              <Card
                key={item}
                value={item}
                isSelected={myVote?.value === item}
                onClick={this.setVote}
                {...{ accentColor }}
              />
            </animated.div>
          )}
        </Transition>
      </div>
    );
  }
}

interface ICardProps {
  value: number;
  isSelected: boolean;
  accentColor: string;
  onClick: (value: number | null) => void;
}

const Card = memo(({ value, isSelected, accentColor, onClick }: ICardProps) => {
  return (
    <div
      className={`cards__card ${isSelected ? 'cards__card--selected' : ''}`}
      style={{ backgroundColor: isSelected ? accentColor : defaultColor }}
      onClick={() => onClick(isSelected ? null : value)}
    >
      <span>{value > 0 ? value : '?'}</span>
    </div>
  );
});
