import { memo } from 'react';
import { ISettings } from '@scrum/shared-lib';
import { useTypedSelector } from '@hooks/typed-selector';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/app-switch/Switch';
import { Section } from '../section/Section';
import { useTransition, animated } from 'react-spring';
import { Checkbox } from '@components/app-checkbox/Checkbox';

interface IProps {
  changeSettings: (key: keyof ISettings, value: ISettings[keyof ISettings]) => void;
}

const TIMES = [30, 60, 120, -1];

export const TimerSection = memo(({ changeSettings }: IProps) => {
  const time = useTypedSelector(({ scrumRoom }) => scrumRoom.room?.settings.timerTime || TIMES[0]);
  const useTimer = useTypedSelector(({ scrumRoom }) => !!scrumRoom.room?.settings.useTimer);
  const useAutoFinish = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useAutoFinish,
  );
  const { t } = useTranslation();

  const contentTransition = useTransition(useTimer, {
    from: { transform: 'translateY(-30px)', opacity: 0 },
    enter: { transform: 'translateY(0px)', opacity: 1 },
    leave: { transform: 'translateY(-30px)', opacity: 0 },
  });

  return (
    <Section title={t('settings.section.timer.title')}>
      <Switch
        text={t('settings.section.timer.use_timer')}
        isChecked={useTimer}
        onClick={() => changeSettings('useTimer', !useTimer)}
      />
      {contentTransition(
        (styles, isVisible) =>
          isVisible && (
            <animated.div style={styles}>
              <Switch
                text={t('settings.section.timer.auto_finish')}
                isChecked={useAutoFinish}
                marginBottom={0}
                onClick={() => changeSettings('useAutoFinish', !useAutoFinish)}
              />
              {TIMES.slice(0, 3).map(num => (
                <Checkbox
                  key={num}
                  text={`${num} ${t('app.seconds_5')}`}
                  isChecked={time === num}
                  onClick={() => changeSettings('timerTime', num)}
                />
              ))}
              {/*<Checkbox*/}
              {/*  text={t('settings.section.timer.other')}*/}
              {/*  isChecked={time === -1}*/}
              {/*  onClick={() => setTime(-1)}*/}
              {/*/>*/}
            </animated.div>
          ),
      )}
    </Section>
  );
});
