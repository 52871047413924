import { createModel } from "@rematch/core";
import {IRoom, TRoomUser, ISettings, ITask, IUser, IVote, TRoomRole} from "@scrum/shared-lib";
import { IColors, USER_COLORS } from "@scrum/shared-lib";
import { RootModel } from "../rematch-models";

type Maybe<T> = T | null;


interface IRoomState {
    room: Maybe<IRoom>;
    tasks: ITask[];
    users: TRoomUser[];
    colors: IColors;
    userColors: Map<string, string>;
    votes: { [taskId: string]: { [userId: string]: IVote } };
    currentUser: Maybe<TRoomUser>;
}

interface IInitPayload {
    room: IRoom;
    users: TRoomUser[];
    tasks: ITask[];
    votes: { [taskId: string]: { [userId: string]: IVote } };
    currentUser: TRoomUser;
}

export const room = createModel<RootModel>()({
    state: {
        room: null,
        tasks: [],
        users: [],
        currentUser: null,
        colors: USER_COLORS,
        userColors: new Map(),
        votes: {},
    } as IRoomState,
    reducers: {
        init(state, payload: IInitPayload) {
            const { users } = payload;

            const colors: IColors = { ...USER_COLORS };
            const userColors = new Map<string, string>();

            users.forEach((user, i) => {
                const value = colors[i + 1];
                const color = Object.keys(value)[0];
                colors[i] = { [color]: true };
                userColors.set(user.id, color);
            });

            return {
                ...state,
                ...payload,
                userColors,
                colors,
            };
        },
        addUser(state, user: TRoomUser) {
            return {
                ...state,
                users: [...state.users, user],
            };
        },
        updateUser(state, user: IUser) {
            return {
                ...state,
                currentUser: user.id === state.currentUser?.id ? {...state.currentUser, ...user } : state.currentUser,
                users: state.users.map(el => (user.id === el.id ? {...el, ...user} : el)),
            };
        },
        removeUser(state, userId: string) {
            return {
                ...state,
                users: state.users.filter(el => el.id !== userId),
            };
        },
        changeUserRole(state, data: { userId: string, role: TRoomRole}) {
            return {
                ...state,
                currentUser: data.userId === state.currentUser?.id ? {...state.currentUser, role: data.role } : state.currentUser,
                users: state.users.map(el => el.id === data.userId ? {...el, role: data.role } : el)
            }
        },
        addTask(state, task: ITask) {
            return {
                ...state,
                tasks: [...state.tasks, task],
            };
        },
        addTaskBatch(state, tasks: ITask[]) {
            return {
                ...state,
                tasks: [...state.tasks, ...tasks],
            };
        },
        switchTask(state, payload: ITask) {
            const { id, stage, timeStartedAt } = payload;
            return {
                ...state,
                room: state.room !== null ? { ...state.room, currentTaskId: id } : state.room,
                tasks: state.tasks.map(el => (el.id === id ? { ...el, stage, timeStartedAt } : el)),
            };
        },
        updateTask(state, { taskId, value }: { taskId: string; value: string }) {
            return {
                ...state,
                tasks: state.tasks.map(el => (el.id === taskId ? { ...el, title: value } : el)),
            };
        },
        setTaskStage(state, payload: ITask) {
            const { id, stage, timeStartedAt } = payload;
            return {
                ...state,
                tasks: state.tasks.map(el => (el.id === id ? { ...el, stage, timeStartedAt } : el)),
            };
        },
        removeTask(state, taskId: string) {
            const copyVotes = { ...state.votes };
            delete copyVotes[taskId]
            return {
                ...state,
                tasks: state.tasks.filter(el => el.id !== taskId),
                votes: copyVotes,
                room:
                    state.room?.currentTaskId === taskId
                        ? { ...state.room, currentTaskId: null }
                        : state.room,
            };
        },
        resetTask(state, task: ITask) {
            const copyVotes = { ...state.votes };
            delete copyVotes[task.id];

            return {
                ...state,
                votes: copyVotes,
                tasks: state.tasks.map(el => (el.id === task.id ? { ...task } : el)),
            };
        },
        addVote(state, payload: { vote: IVote; taskId: string }) {
            const { vote, taskId } = payload;

            return {
                ...state,
                votes: {
                    ...state.votes,
                    [taskId]: { ...(state.votes || {})[taskId], [vote.userId]: vote },
                },
            };
        },
        removeVote(state, payload: { voteId: string; taskId: string; userId: string }) {
            const { taskId, userId } = payload;

            const copyVotes = { ...state.votes };
            const copyVotesByTask = { ...copyVotes[taskId] };
            delete copyVotesByTask[userId];
            let votes: { [taskId: string]: { [userId: string]: IVote } };
            if (Object.values(copyVotesByTask).length) {
                votes = { ...copyVotes, [taskId]: copyVotesByTask };
            } else {
                delete copyVotes[taskId];
                votes = { ...copyVotes };
            }

            return {
                ...state,
                votes,
            };
        },
        updateRoomSettings(state, settings: ISettings) {
            return {
                ...state,
                room: { ...state.room!, settings },
            };
        },
    },
})
