import { COLORS, ETaskStage, ITask } from '@scrum/shared-lib';
import { memo, useCallback, useState } from 'react';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { useTranslation } from 'react-i18next';
import { DropDownMenu } from '@components/drop-menu/DropDownMenu';
import { AppButton } from '@components/app-button/AppButton';
import './styles.sass';
import { Utils } from '@utils/utils';
import { useTypedSelector } from '@hooks/typed-selector';
import { shallowEqual } from 'react-redux';
import { ProgressIcon } from '@components/svg/ProgressIcon';
import { Icon } from '@components/app-icon/Icon';

interface IProps extends ITask {
  isActive: boolean;
  accentColor: string;
  startTask: (id: string, stage: ETaskStage) => void;
  removeTask: (id: string) => void;
  editTask: (id: string) => void;
  viewOnly: boolean;
}

export const Task = memo(
  ({
    id,
    title,
    accentColor,
    startTask,
    editTask,
    removeTask,
    isActive,
    stage,
    viewOnly,
  }: IProps) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const { t } = useTranslation();

    const toggleMenuVisible = useCallback(() => {
      setMenuVisible(prev => !prev);
    }, []);

    const average = useTypedSelector(({ scrumRoom: { votes } }) => {
      if (votes[id]) {
        return Utils.calculateAverage(votes[id]).average;
      } else {
        return 0;
      }
    }, shallowEqual);

    const taskValue = stage === ETaskStage.RESULTS ? average || 0 : 0;

    return (
      <div
        className="task"
        style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.6) }}
      >
        {!!taskValue && (
          <div
            className="task__result"
            style={{ border: `1px solid ${accentColor}`, backgroundColor: accentColor }}
          >
            <span style={{ color: COLORS.WHITE }}>{taskValue}</span>
          </div>
        )}
        <div className="task__start">
          {viewOnly ? (
            <div className="task__progress">
              {isActive ? <ProgressIcon /> : <Icon icon="Circle" color={accentColor} />}
            </div>
          ) : isActive ? (
            <div className="task__progress">
              <ProgressIcon />
            </div>
          ) : (
            <ClickableIcon
              icon="PlayCircle"
              iconColor={accentColor}
              onClick={() => startTask(id, stage)}
            />
          )}
          {}
          <span className="task__title">{title}</span>
        </div>
        {!viewOnly && (
          <DropDownMenu
            isVisible={menuVisible}
            closeMenu={toggleMenuVisible}
            button={
              <ClickableIcon
                icon="MoreVertical"
                iconColor={COLORS.convertHEXtoRGBA(COLORS.WHITE, 0.6)}
                onClick={toggleMenuVisible}
              />
            }
          >
            <AppButton
              reverse
              icon="Edit3"
              text={t('task.menu.edit_text')}
              iconColor={COLORS.GRAY_6}
              textColor={COLORS.GRAY_6}
              iconSize={20}
              width="210px"
              paddingHorizontal={20}
              backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
              hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
              borderRadius={0}
              onClick={() => editTask(id)}
            />
            <AppButton
              reverse
              icon="Trash2"
              text={t('task.menu.remove')}
              paddingHorizontal={20}
              width="210px"
              iconColor={COLORS.RED}
              backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
              hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
              textColor={COLORS.RED}
              iconSize={20}
              borderRadius={0}
              onClick={() => removeTask(id)}
            />
          </DropDownMenu>
        )}
      </div>
    );
  },
);
