import { memo, ReactNode } from 'react';
import { COLORS } from '@scrum/shared-lib';
import { AppButton } from '@components/app-button/AppButton';
import { animated, config, useTransition } from 'react-spring';
import './styles.sass';
import { useTranslation } from 'react-i18next';

interface IProps {
  isVisible: boolean;
  title: string;
  description?: string;
  useCloseButton?: boolean;
  successButton?: ReactNode | undefined;
  closeModal: () => void;
  maxHeight?: number;
  children?: ReactNode | undefined;
}

export const Modal = memo<IProps>(
  ({
    isVisible,
    title,
    description,
    children,
    useCloseButton = true,
    maxHeight = 250,
    successButton,
    closeModal,
  }) => {
    const { t } = useTranslation();

    const backDropTransitions = useTransition(isVisible, {
      from: { backgroundColor: COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0) },
      enter: { backgroundColor: COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0.6) },
      leave: { backgroundColor: COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0) },
      config: { duration: 200 },
    });

    const contentTransitions = useTransition(isVisible, {
      enter: { transform: 'perspective(300px) rotateX(0deg) scale(1)' },
      from: { transform: 'perspective(300px) rotateX(90deg) scale(0.3)' },
      leave: { transform: 'perspective(300px) rotateX(90deg) scale(0.3)' },
      config: config.stiff,
    });

    return backDropTransitions(
      (styles, canShow) =>
        canShow && (
          <animated.div className="modal" style={styles} onClick={closeModal}>
            {contentTransitions(
              (styles, canShow) =>
                canShow && (
                  <animated.div
                    className="modal__content"
                    style={{
                      ...styles,
                      maxHeight,
                      backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.9),
                    }}
                    onClick={e => e.stopPropagation()}
                  >
                    <span className="modal__title">{t(title)}</span>
                    {!!description && <span className="modal__description">{t(description)}</span>}
                    {children}
                    <div className="modal__buttons">
                      {successButton}
                      {useCloseButton && (
                        <AppButton
                          text={t('app.cancel')}
                          backgroundColor={COLORS.convertHEXtoRGBA(COLORS.RED, 0.6)}
                          hoverColor={COLORS.RED}
                          onClick={closeModal}
                        />
                      )}
                    </div>
                  </animated.div>
                ),
            )}
          </animated.div>
        ),
    );
  },
);
