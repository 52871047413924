import { memo, useEffect, useState } from 'react';
import { Modal } from '@components/modal/Modal';
import { ApiService } from '@services/AppApi';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { useTranslation } from 'react-i18next';
import ContentLoader from 'react-content-loader';

interface IProps {
  roomInfoId: string | null;
  closeRoomInfoModal: () => void;
}

type TRoomInfoResponse = Awaited<ReturnType<typeof ApiService.getRoomInfo>>;

export const RoomInfoModal = memo(({ roomInfoId, closeRoomInfoModal }: IProps) => {
  const [info, setInfo] = useState<TRoomInfoResponse | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (roomInfoId) {
      ApiService.getRoomInfo(roomInfoId).then(data => {
        console.log('DARA', data);
        setInfo(data);
      });
    }
  }, [roomInfoId]);

  return (
    <Modal
      isVisible={!!roomInfoId}
      maxHeight={400}
      title="asd"
      successButton={
        <AppButton
          text={t('rooms.code.join_btn')}
          textColor={COLORS.YELLOW}
          marginRight={16}
          backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.3)}
          hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.9)}
        />
      }
      closeModal={closeRoomInfoModal}
    >
      {info ? (
        <div>
          <span>info</span>
        </div>
      ) : (
        <LoadingSkeleton />
      )}
    </Modal>
  );
});

const LoadingSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={500}
      height={200}
      viewBox="0 0 500 200"
      backgroundColor="#4b4949"
      foregroundColor="#878787"
    >
      <rect x="1" y="137" rx="3" ry="3" width="410" height="6" />
      <rect x="1" y="153" rx="3" ry="3" width="380" height="6" />
      <rect x="1" y="169" rx="3" ry="3" width="178" height="6" />
      <circle cx="70" cy="80" r="18" />
      <circle cx="120" cy="80" r="18" />
      <circle cx="169" cy="80" r="18" />
      <circle cx="20" cy="80" r="18" />
      <rect x="0" y="9" rx="3" ry="3" width="178" height="7" />
      <rect x="1" y="118" rx="3" ry="3" width="130" height="6" />
      <rect x="1" y="39" rx="3" ry="3" width="130" height="6" />
    </ContentLoader>
  );
};
