import { useTypedSelector } from '@hooks/typed-selector';
import { Section } from '../section/Section';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from '@components/color-picker/ColorPicker';
import { ARR_USER_COLORS } from '@scrum/shared-lib';
import { useCallback } from 'react';
import { SocketService } from '@services/AppSocket';

export const AvatarSection = () => {
  const avatarColor = useTypedSelector(({ scrumRoom }) => scrumRoom.currentUser?.avatarColor || '');
  const { t } = useTranslation();

  const changeColor = useCallback((color: string) => {
    SocketService.send('updateUser', { fields: { avatarColor: color } });
  }, []);

  return (
    <Section title={t('settings.section.avatar.title')}>
      <ColorPicker colors={ARR_USER_COLORS} activeColor={avatarColor} {...{ changeColor }} />
    </Section>
  );
};
