import { useRxSelector } from '@scrum/rx-store';
import { IRxStore } from '../rx-store/rx-store';

export interface TypedUseSelectorHook<TState> {
  <TSelected>(
    selector: (state: TState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
  ): TSelected;
}

export const useTypedRxSelector: TypedUseSelectorHook<IRxStore> =
  useRxSelector as TypedUseSelectorHook<IRxStore>;
