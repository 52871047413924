import './styles.sass';
import { useSpring, animated, config } from 'react-spring';
import { COLORS } from '@scrum/shared-lib';

interface IProps {
  text: string;
  isChecked: boolean;
  marginBottom?: number;
  onClick?: () => void;
}

export const Switch = ({ text, isChecked, onClick, marginBottom = 10 }: IProps) => {
  const dotStyle = useSpring({
    transform: isChecked ? 'translateX(19px)' : 'translateX(0px)',
    config: {
      ...config.wobbly,
      tension: 180,
      friction: 17,
    },
  });

  return (
    <div className="switch" style={{ marginBottom }} onClick={onClick}>
      <span className="switch__text">{text}</span>
      <animated.div
        className="switch__container"
        style={{ backgroundColor: isChecked ? COLORS.GREEN_1 : COLORS.GRAY_7 }}
      >
        <animated.div className="switch__dot" style={dotStyle} />
      </animated.div>
    </div>
  );
};
