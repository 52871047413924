import axios from 'axios';
import { IRoom } from './room';
import { IUser } from './user';
import { IRoomUserInfo } from "./room-user-info";
import { ITask } from "./task";
import { EAuthMessage } from "./auth-messages";

type ICodeResponse =
  | { message: 'CODE_SUCCESSFUL'; roomId: string }
  | { message: 'ROOM_NOT_FOUND' };

export type TUnsubRoom =
  | { message: EAuthMessage.ROOM_UNSUBSCRIBED, roomId: string }
  | { message: EAuthMessage.ROOM_NOT_FOUND };

export type TRemoveRoom =
  | { message: EAuthMessage.ROOM_REMOVED, roomId: string }
  | { message: EAuthMessage.ROOM_NOT_FOUND }
  | { message: EAuthMessage.ACCESS_ERROR };

export type TRoomInfo = {
  room: IRoom | null;
  currentTask: ITask | null;
  users: IUser[]
}

export class AppApi {
  protected instance;

  constructor(baseUrl: string, secretKey: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: 'application/json',
      },
    })

    this.instance.interceptors.response.use(undefined, error => Promise.reject(error.response))
  }

  public setAccessToken(token: string) {
    return new Promise<void>(res => {
      this.instance.defaults.headers.common.Authorization = token
      res()
    })
  }

  public async createRoom(roomName: string, userId: string) {
    try {
      return (await this.instance.post<{ roomId: string }>('/room', { roomName, userId })).data;
    } catch (e) {
      console.log('createRoom ERROR', e);
      throw e;
    }
  }

  public async getRoomByCode(registrationCode: string) {
    try {
      return (await this.instance.get<ICodeResponse>(`/room-code/${registrationCode}`)).data;
    } catch (e) {
      console.log('getRoomByCode ERROR', e);
      return (e as { data?: unknown })?.data as { message: 'ROOM_NOT_FOUND' };
    }
  }

  public async unsubscribeRoom(roomId: string) {
    try {
      return (await this.instance.post<TUnsubRoom>('/room/unsubscribe', {roomId})).data
    } catch (e) {
      console.log('unsubscribeRoom ERROR', e);
      throw e;
    }
  }

  public async removeRoom(roomId: string) {
    try {
      return (await this.instance.post<TRemoveRoom>('/room/remove', {roomId})).data
    } catch (e) {
      console.log('removeRoom ERROR', e);
      throw e;
    }
  }

  public async getRoomInfo(roomId: string) {
    try {
      return (await this.instance.get<TRoomInfo>(`/room-info/${roomId}`)).data
    }  catch (e) {
      console.log('getRoomInfo ERROR', e);
      throw e;
    }
  }

  public async getRooms() {
    return (await this.instance.get<{rooms: IRoom[], userInfos: IRoomUserInfo[], lastTasks: ITask[] }>('/rooms')).data;
  }

  public async getRoom(roomId: string) {
    try {
      return (await this.instance.get<IRoom>(`/room/${roomId}`)).data;
    } catch (e) {
      console.log('getRoom ERROR', e);
      throw e;
    }
  }

  public async createFakeUser(name: string) {
    try {
      return (await this.instance.post<{ user: IUser, accessToken: string }>('/fake-user', { userName: name })).data;
    } catch (e) {
      console.log('createFakeUser ERROR', e);
      throw e;
    }
  }

  public async getUser(userId: string) {
    try {
      return (await this.instance.get<IUser>(`/user/${userId}`)).data;
    } catch (e) {
      console.log('getUser ERROR', e);
      throw e;
    }
  }

  public async getRoomParticipants(users: string[]) {
    try {
      return (await this.instance.post<IUser []>('/participants', { users })).data;
    } catch (e) {
      console.log('getRoomParticipants ERROR', e);
      throw e;
    }
  }
}
