import { memo } from 'react';
import { Switch } from '@components/app-switch/Switch';
import { Section } from '../section/Section';
import { ISettings } from '@scrum/shared-lib';
import { useTypedSelector } from '@hooks/typed-selector';
import { useTranslation } from 'react-i18next';

interface IProps {
  changeSettings: (key: keyof ISettings, value: boolean) => void;
}

export const TaskSection = memo(({ changeSettings }: IProps) => {
  const useFirstStage = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useFirstStage,
  );
  const { t } = useTranslation();

  return (
    <Section title={t('settings.section.tasks.title')}>
      <Switch
        text={t('settings.section.tasks.acquaintance')}
        isChecked={useFirstStage}
        onClick={() => changeSettings('useFirstStage', !useFirstStage)}
      />
    </Section>
  );
});
