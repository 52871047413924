import React from 'react';
import { MainRouterSwitch } from './Router';
import './main.sass';

function App() {
  return (
    <div className="app">
      <MainRouterSwitch />
    </div>
  );
}

export default App;
