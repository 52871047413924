import { memo, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { COLORS, ETaskStage, ITask, IVote, titleTranslate } from '@scrum/shared-lib';
import { Timer } from '@components/timer/Timer';
import { VotingProgress } from '@components/voting-progress/VotingProgress';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '@hooks/typed-selector';
import { shallowEqual } from 'react-redux';
import { SocketService } from '@services/AppSocket';
import './styles.sass';
import { useTypedRxSelector } from '@hooks/typed-rx-selector';

interface IProps {
  currentTask: ITask;
  accentColor: string;
  votes: { [userId: string]: IVote } | null;
  children?: ReactNode;
}

export const ActivityCard = memo(({ currentTask, votes, accentColor, children }: IProps) => {
  const useAutoFinish = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useAutoFinish,
  );
  const useTimer = useTypedSelector(({ scrumRoom }) => !!scrumRoom.room?.settings.useTimer);
  const useTaskNotify = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useTaskNotify,
  );
  const toastService = useTypedRxSelector(({ common }) => common.toastService);
  const { t } = useTranslation();

  const isVoting = currentTask.stage === ETaskStage.VOTING;
  const isNotStarted = currentTask.stage === ETaskStage.NOT_STARTED;
  const isResults = currentTask.stage === ETaskStage.RESULTS;
  const usersCount = useTypedSelector(({ scrumRoom }) => scrumRoom.users.length, shallowEqual);
  const timeDuration = useTypedSelector(
    ({ scrumRoom }) => scrumRoom?.room?.settings.timerTime || 30,
  );
  const votesCount = useMemo(() => Object.values(votes || {}).length, [votes]);

  const onTimeEnd = useCallback(() => {
    if (useAutoFinish) {
      SocketService.send('changeTaskStage', { taskId: currentTask.id, stage: ETaskStage.RESULTS });
    }
  }, [currentTask.id, useAutoFinish]);

  useEffect(() => {
    if (currentTask.id && isVoting && useTaskNotify) {
      toastService.show({ text: 'Началось новое голосование', clearQueue: true });
    }
  }, [currentTask.id, isVoting, toastService, useTaskNotify]);

  return (
    <div>
      <div
        className="activity-card"
        style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.6) }}
      >
        <span className="activity-card__title" style={{ color: accentColor }}>
          {t(titleTranslate[currentTask.stage])}
        </span>
        <span className="activity-card__task">{currentTask.title}</span>
        <div
          className="activity-card__divider"
          style={{ backgroundColor: COLORS.convertHEXtoRGBA(accentColor, 0.3) }}
        />
        <div className="activity-card__info">
          {(isNotStarted || (isVoting && useTimer)) && currentTask.timeStartedAt && (
            <Timer
              isDisabled={isNotStarted}
              timeStartedAt={currentTask.timeStartedAt}
              isBlocked={!useTimer}
              duration={timeDuration}
              {...{ onTimeEnd }}
            />
          )}
          {(isVoting || isResults) && <VotingProgress count={votesCount} maxCount={usersCount} />}
        </div>
      </div>
      {children}
    </div>
  );
});
