import React, { useContext, useEffect, useState } from 'react';
const StoreContext = React.createContext({});
class Store {
    constructor(store) {
        this.store = store;
        this.getState.bind(this);
    }
    getState() {
        return this.store;
    }
}
export const RxProvider = ({ store, children }) => {
    return (React.createElement(StoreContext.Provider, { value: store }, children));
};
export const useObservable = (observable$, defaultValue) => {
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        const subscription$ = observable$.subscribe(setValue);
        return () => subscription$.unsubscribe();
    }, [observable$]);
    return [value, setValue];
};
export const useRxSelector = (fn) => {
    return fn(useContext(StoreContext).getState());
};
export const withRx = (fn) => (Component) => (props) => {
    const contextProps = useContext(StoreContext);
    return React.createElement(Component, Object.assign({}, props, fn === null || fn === void 0 ? void 0 : fn(contextProps.getState())));
};
export const createRxStore = (initialState) => {
    return new Store(initialState);
};
