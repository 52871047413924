import { FC, useState } from 'react';
import { COLORS } from '@scrum/shared-lib';
import * as FeatherIcons from 'react-feather';
import { Icon } from '@components/app-icon/Icon';
import './styles.sass';

interface IProps {
  size?: number;
  onClick?: () => void;
  iconSize?: number;
  iconColor?: string;
  backgroundColor?: string;
  border?: string;
  icon?: keyof typeof FeatherIcons;
  text?: string;
  marginRight?: number;
}

const defaultBackground = COLORS.convertHEXtoRGBA(COLORS.WHITE, 0.1);

export const ClickableIcon: FC<IProps> = ({
  size = 40,
  backgroundColor = defaultBackground,
  icon,
  text,
  iconSize = 26,
  iconColor = COLORS.WHITE,
  border,
  marginRight,
  onClick,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <button
      className="icon-button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={e => {
        e.stopPropagation();
        onClick?.();
      }}
      style={{
        width: size,
        height: size,
        border,
        marginRight,
        backgroundColor: hovered ? backgroundColor : COLORS.TRANSPARENT,
      }}
    >
      {icon ? (
        <Icon size={iconSize} color={iconColor} {...{ icon }} />
      ) : text ? (
        <span style={{ fontSize: iconSize, color: iconColor, fontFamily: 'Space Grotesk' }}>
          {text}
        </span>
      ) : null}
    </button>
  );
};
