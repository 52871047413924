import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { store } from '@store/store';
import { ToastProvider } from '@components/toast-provider/ToastProvider';
import { RxProvider } from '@scrum/rx-store';
import { rxStore } from './rx-store/rx-store';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import '../src/localization/i18';

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <RxProvider store={rxStore}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </RxProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
