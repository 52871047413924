import { ApiService } from '@services/AppApi';
import { IUser } from '@scrum/shared-lib';
import { ACCESS_KEY, ROOM_ID_KEY } from '@constants/local-storage';
import jwtDecode from 'jwt-decode';

class AppAuthService {
  public async setAccessToken(accessToken: string) {
    localStorage.setItem(ACCESS_KEY, accessToken);
    await ApiService.setAccessToken(accessToken);
  }

  public getAccessToken() {
    return localStorage.getItem(ACCESS_KEY);
  }

  public removeAccessToken() {
    localStorage.removeItem(ACCESS_KEY);
  }

  public setRoom(id: string) {
    return new Promise<void>(resolve => {
      localStorage.setItem(ROOM_ID_KEY, id);
      resolve();
    });
  }

  public removeRoom() {
    localStorage.removeItem(ROOM_ID_KEY);
  }

  public getRoom() {
    return localStorage.getItem(ROOM_ID_KEY);
  }

  public async getUser() {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      const decodedAccess = jwtDecode<{ userId: string; fname: string }>(accessToken);
      return await ApiService.getUser(decodedAccess.userId);
    }
    return null;
  }

  public async login(): Promise<
    { message: 'AUTHORIZATION_SUCCESS'; user: IUser } | { message: 'UNAUTHORIZED' }
  > {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      await this.setAccessToken(accessToken);
      const decodedAccess = jwtDecode<{ userId: string; fname: string }>(accessToken);
      const user = await ApiService.getUser(decodedAccess.userId);
      return { message: 'AUTHORIZATION_SUCCESS', user };
    } else {
      return { message: 'UNAUTHORIZED' };
    }
  }

  public logout() {
    this.removeAccessToken();
    this.removeRoom();
  }
}

export const AuthService = new AppAuthService();
