import { useEffect, useMemo, useState } from 'react';
import { Icon } from '@components/app-icon/Icon';
import { COLORS } from '@scrum/shared-lib';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useTransition, animated, config, useSpring } from 'react-spring';
import { Utils } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import 'react-circular-progressbar/dist/styles.css';
import './styles.sass';

interface IProps {
  duration?: number;
  isDisabled?: boolean;
  isBlocked?: boolean;
  timeStartedAt: string;
  onTimeEnd?: () => void;
  onTimeChanged?: () => void;
}

export const Timer = ({
  isDisabled,
  duration = 10,
  isBlocked,
  timeStartedAt,
  onTimeEnd,
}: IProps) => {
  const initialTime = isDisabled ? duration : Utils.getDifferentTimerTime(timeStartedAt, duration);
  const [time, setTime] = useState(initialTime);
  const { t } = useTranslation();

  useEffect(() => {
    setTime(initialTime);
  }, [duration]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!isDisabled && !isBlocked) {
      interval = setInterval(() => {
        setTime(() => {
          const next = Utils.getDifferentTimerTime(timeStartedAt, duration);
          if (next <= 0) {
            onTimeEnd?.();
            clearInterval(interval);
            return 0;
          }
          return next;
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [duration, isDisabled, onTimeEnd, timeStartedAt]);

  const transitions = useTransition(!isDisabled, {
    enter: { transform: 'scale(1)' },
    from: { transform: 'scale(0)' },
    config: config.gentle,
  });

  const textSpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.stiff,
  });

  const percent = useMemo(() => Utils.calculateTimerPercentage(duration, time), [duration, time]);
  const color = isDisabled ? COLORS.GRAY_8 : percent > 85 ? COLORS.RED : COLORS.YELLOW;

  return (
    <div className="timer">
      {isDisabled && <Icon icon="Clock" size={32} color={COLORS.GRAY_7} />}
      {transitions(
        (styles, canShow) =>
          canShow && (
            <animated.div
              className="time-progress"
              style={{ ...styles, border: `2px solid ${color}` }}
            >
              <CircularProgressbar
                value={percent}
                strokeWidth={50}
                background={false}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathColor: color,
                  trailColor: COLORS.TRANSPARENT,
                  pathTransitionDuration: 0.4,
                })}
              />
              {percent === 100 && (
                <div className="time-progress__finish">
                  <Icon icon="X" size={18} />
                </div>
              )}
            </animated.div>
          ),
      )}
      <animated.span className="timer__text" style={{ ...textSpring, color }}>
        {isBlocked ? t('timer.blocked') : Utils.formatTime(time)}
      </animated.span>
    </div>
  );
};
