import { useTranslation } from 'react-i18next';
import { Section } from '../section/Section';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { COLORS } from '@scrum/shared-lib';
import './styles.sass';

export const LangSection = () => {
  const { t, i18n } = useTranslation();

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Section title={t('settings.section.lang.title')}>
      <div className="language">
        {Object.keys(i18n.store.data).map(lang => {
          const color = lang === i18n.language ? COLORS.YELLOW : COLORS.WHITE;
          return (
            <ClickableIcon
              key={lang}
              text={lang.toUpperCase()}
              iconSize={16}
              size={40}
              marginRight={16}
              iconColor={color}
              border={`2px solid ${color}`}
              onClick={() => changeLang(lang)}
            />
          );
        })}
      </div>
    </Section>
  );
};
