import { memo } from 'react';

export const CardSVG = memo(() => {
  return (
    <svg
      width="400"
      height="413"
      viewBox="0 0 272 281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.000488281"
        y="168.389"
        width="160"
        height="220"
        rx="10"
        transform="rotate(-60 -0.000488281 168.389)"
        fill="#A77B39"
      />
      <rect
        x="2.48975"
        y="124.702"
        width="160"
        height="220"
        rx="10"
        transform="rotate(-45 2.48975 124.702)"
        fill="#1F1F1F"
      />
      <rect
        x="17.0952"
        y="79.999"
        width="160"
        height="220"
        rx="10"
        transform="rotate(-30 17.0952 79.999)"
        fill="#262626"
      />
      <rect
        x="48.0015"
        y="42.5752"
        width="160"
        height="220"
        rx="10"
        transform="rotate(-15 48.0015 42.5752)"
        fill="#434343"
      />
      <rect x="87.6021" y="10.1211" width="160" height="220" rx="10" fill="#595959" />
    </svg>
  );
});
