import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@components/app-switch/Switch';
import { Section } from '@pages/room/settings/section/Section';
import { useTypedSelector } from '@hooks/typed-selector';
import { ISettings } from '@scrum/shared-lib';

interface IProps {
  changeSettings: (key: keyof ISettings, value: boolean) => void;
}

export const CommonSection = memo(({ changeSettings }: IProps) => {
  const useAnonymousVote = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useAnonymousVote,
  );
  const useTaskNotify = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useTaskNotify,
  );
  const showTasks = useTypedSelector(({ scrumRoom }) => !!scrumRoom.room?.settings.showTasks);
  const { t } = useTranslation();

  return (
    <Section title={t('settings.section.common')}>
      <Switch
        text={t('settings.section.common.notification')}
        isChecked={useTaskNotify}
        onClick={() => changeSettings('useTaskNotify', !useTaskNotify)}
      />
      <Switch
        text={t('settings.section.common.anonymous')}
        isChecked={useAnonymousVote}
        onClick={() => changeSettings('useAnonymousVote', !useAnonymousVote)}
      />
      <Switch
        text={t('settings.section.common.tasks')}
        isChecked={showTasks}
        onClick={() => changeSettings('showTasks', !showTasks)}
      />
    </Section>
  );
});
