import { ComponentProps, memo, useCallback, useMemo } from 'react';
import { COLORS, TRoomUser } from '@scrum/shared-lib';
import { Utils } from '@utils/utils';
import { useTransition, animated, config } from 'react-spring';
import { Icon } from '@components/app-icon/Icon';
import { ContextMenu, ContextMenuTrigger } from '@components/context-menu/ContextMenu';
import { useTranslation } from 'react-i18next';
import { SocketService } from '@services/AppSocket';
import { Leader } from '@components/svg/Leader';
import './styles.sass';

interface IProps extends TRoomUser {
  voteValue: string | number | undefined;
  isVoting: boolean;
  canShowResult: boolean;
  isCurrentUser: boolean;
  isLeading: boolean;
}

type TContextButtonProps = ComponentProps<typeof ContextMenu>;

export const UserItem = memo(
  ({
    fname,
    lname,
    canShowResult,
    voteValue,
    isVoting,
    id,
    avatarColor,
    role,
    isCurrentUser,
    isLeading,
  }: IProps) => {
    const { t } = useTranslation();

    const isResult = canShowResult && typeof voteValue === 'number';

    const checkTransitions = useTransition(typeof voteValue === 'number', {
      enter: { transform: 'scale(1)' },
      from: { transform: 'scale(0)' },
      leave: { transform: 'scale(0)' },
      config: config.stiff,
    });

    const resultTransitions = useTransition(isResult, {
      enter: { transform: 'scale(1)' },
      from: { transform: 'scale(0)' },
      leave: { transform: 'scale(0)' },
      config: config.stiff,
    });

    const votingTransition = useTransition(isVoting, {
      enter: { opacity: 1 },
      from: { opacity: 0 },
      leave: { opacity: 0 },
      config: config.default,
    });

    const leadingTransition = useTransition(role === 'leading', {
      enter: { opacity: 1, transform: 'translateX(50%) rotate(0deg)' },
      from: { opacity: 1, transform: 'translateX(140%) rotate(30deg)' },
      leave: { opacity: 0, transform: 'translateX(140%) rotate(30deg)' },
      config: config.default,
    });

    const kickUser = useCallback(() => {
      console.log('ID', id);
    }, [id]);

    const changeUserRole = useCallback(() => {
      SocketService.send('changeRole', {
        userId: id,
        role: role === 'leading' ? 'participant' : 'leading',
      });
    }, [id, role]);

    const contextButtons = useMemo<TContextButtonProps['buttons']>(() => {
      const isParticipant = role === 'participant';
      return [
        {
          icon: isParticipant ? 'Star' : 'Minus',
          size: 25,
          color: isParticipant ? COLORS.YELLOW : COLORS.RED,
          text: t(isParticipant ? 'room.context_menu.set_role' : 'room.context_menu.remove_role'),
          onClick: changeUserRole,
        },
        {
          icon: 'UserMinus',
          size: 25,
          color: COLORS.RED,
          text: t('room.context_menu.kick_user'),
          onClick: kickUser,
        },
      ];
    }, [changeUserRole, kickUser, role, t]);

    return (
      <div className="user">
        <ContextMenuTrigger id={id}>
          {leadingTransition(
            (styles, canShow) =>
              canShow && (
                <animated.div style={styles} className="user__leader">
                  <Leader />
                </animated.div>
              ),
          )}
          <div className="user__avatar" style={{ backgroundColor: avatarColor }}>
            <span className="user__name-shortcuts">{Utils.nameSymbols(fname, lname)}</span>
            {votingTransition(
              (styles, canShow) =>
                canShow && (
                  <animated.div
                    className="user__voting"
                    style={{
                      ...styles,
                      backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.5),
                    }}
                  />
                ),
            )}
            {resultTransitions((styles, canShow) =>
              canShow ? (
                <animated.div style={styles} className="user__result">
                  <span>{voteValue && voteValue > 0 ? voteValue : '?'}</span>
                </animated.div>
              ) : (
                checkTransitions(
                  (styles, canVisible) =>
                    canVisible && (
                      <animated.div style={styles} className="user__check">
                        <Icon icon="CheckCircle" size={22} />
                      </animated.div>
                    ),
                )
              ),
            )}
            <div />
          </div>
          <ContextMenu id={id} buttons={contextButtons} isDisabled={isCurrentUser || !isLeading} />
        </ContextMenuTrigger>
        <span className="user__name">{Utils.nameShortcut(fname, lname)}</span>
      </div>
    );
  },
);
