import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthService } from '@services/AuthService';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { ApiService } from '@services/AppApi';
import './styles.sass';

type TMessage = 'AUTH_PENDING' | 'AUTH_CONFIRMED' | 'INTERNAL_ERROR' | 'UNAUTHORIZED';

const titleTranslate = {
  AUTH_PENDING: 'telegram.pending.title',
  AUTH_CONFIRMED: 'telegram.success.title',
  INTERNAL_ERROR: 'telegram.internal_error.title',
  UNAUTHORIZED: 'telegram.error.title',
};

const messageTranslate = {
  AUTH_PENDING: 'telegram.pending.description',
  AUTH_CONFIRMED: 'telegram.success.description',
  INTERNAL_ERROR: 'telegram.internal_error.description',
  UNAUTHORIZED: 'telegram.error.description',
};

export const TelegramPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState<TMessage>('UNAUTHORIZED');

  const { t } = useTranslation();
  const { chatId } = useParams<{ chatId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const token = AuthService.getAccessToken();

    if (token && chatId) {
      ApiService.sendTelegramOffer(chatId)
        .then(resp => {
          console.log('resp', resp);
          setStatus(resp.data.message);
        })
        .catch(() => setStatus('INTERNAL_ERROR'))
        .finally(() => setIsLoading(false));
    } else {
      setStatus('UNAUTHORIZED');
      setIsLoading(false);
    }
  }, []);

  const goToAuth = useCallback(() => {
    navigate('/auth', { replace: true });
  }, [navigate]);

  return (
    <div className="telegram">
      <div className="telegram__content">
        {isLoading ? (
          <Lottie
            width={350}
            height={350}
            isClickToPauseDisabled
            options={{ animationData: require('../../assets/animations/main-spinner.json') }}
          />
        ) : status === 'UNAUTHORIZED' || status === 'INTERNAL_ERROR' ? (
          <>
            <Lottie
              width={300}
              height={300}
              isClickToPauseDisabled
              options={{
                animationData: require('../../assets/animations/error.json'),
                loop: false,
              }}
            />
            <span className="telegram__status">{t(titleTranslate[status])}</span>
            <span className="telegram__description">{t(messageTranslate[status])}</span>
            {status === 'UNAUTHORIZED' && (
              <AppButton
                hoverColor={COLORS.convertHEXtoRGBA(COLORS.GREEN_1, 0.6)}
                backgroundColor={COLORS.GREEN_1}
                icon="LogIn"
                text="Authorize"
                width="70%"
                marginTop={20}
                onClick={goToAuth}
              />
            )}
          </>
        ) : (
          <>
            <Lottie
              width={300}
              height={300}
              isClickToPauseDisabled
              options={{
                animationData: require('../../assets/animations/success.json'),
                loop: false,
              }}
            />
            <span className="telegram__status">{t(titleTranslate[status])}</span>
            <span className="telegram__description">{t(messageTranslate[status])}</span>
          </>
        )}
      </div>
    </div>
  );
};
