export const ru = {
  translation: {
    'auth.code': 'Код приглашения',
    'auth.room_name': 'Название комнаты',
    'auth.enter_name': 'Введите имя',
    'auth.join': 'Войти',
    'auth.leave': 'Выйти',
    'app.seconds_1': 'секунда',
    'app.seconds_2': 'секунды',
    'app.seconds_5': 'секунд',
    'app.minutes_1': 'минута',
    'app.minutes_2': 'минуты',
    'app.minutes_5': 'минут',
    'app.cancel': 'Отменить',
    'title.first': 'Ознакомление',
    'title.voting': 'Голосование',
    'title.results': 'Результаты',
    'controls.first': 'Начать голосование',
    'controls.voting': 'Перейти к результатам',
    'controls.results': 'Следующая задача',
    'controls.empty_task': 'Нет задачи',
    'task.menu.remove': 'Удалить',
    'task.menu.edit_text': 'Изменить текст',
    'task.menu.edit_description': 'Изменить описание',
    'task.menu.add_description': 'Добавить описание',
    'tasks.input.placeholder': 'Введите название задачи',
    'settings.section.common': 'Общее',
    'settings.section.common.notification': 'Уведомления',
    'settings.section.common.anonymous': 'Анонимное голосование',
    'settings.section.common.tasks': 'Отображать список задач',
    'settings.section.tasks.title': 'Задачи',
    'settings.section.tasks.acquaintance': 'Этап ознакомления',
    'settings.section.timer.title': 'Таймер',
    'settings.section.timer.use_timer': 'Использовать таймер',
    'settings.section.timer.auto_finish': 'Автоматическое завершение голосования',
    'settings.section.timer.other': 'Другое',
    'settings.section.timer.minutes': 'Mинуты',
    'settings.section.timer.seconds': 'Секунды',
    'settings.section.profile.title': 'Профиль',
    'settings.section.profile.fname': 'Имя',
    'settings.section.profile.lname': 'Фамилия',
    'settings.section.colors.title': 'Цветовая схема',
    'settings.section.avatar.title': 'Цвет аватара',
    'settings.section.colors.room': 'Комната',
    'settings.section.colors.tasks': 'Задачи',
    'settings.section.lang.title': 'Язык интерфейса',
    'toast.error.room_not_found': 'Неверный код комнаты!',
    'toast.info.unsub_room': 'Вы успешно отписались от комнаты',
    'toast.info.remove_room': 'Комната успешно удалена',
    'toast.info.copy_code': 'Код успешно скопирован',
    'toast.info.copy_link': 'Ссылка успешно скопирована',
    'room.menu.copy_code': 'Скопировать код',
    'room.menu.copy_link': 'Скопировать ссылку',
    'room.menu.mote_info': 'Подробнее',
    'room.menu.remove': 'Удалить комнату',
    'room.menu.unsubscribe': 'Покинуть комнату',
    'room.context_menu.set_role': 'Сдлеать лидером',
    'room.context_menu.remove_role': 'Убрать роль лидера',
    'room.context_menu.kick_user': 'Исключить участника',
    'rooms.title': 'Мои комнаты',
    'rooms.context_menu.enter_by_code': 'Войти по коду',
    'rooms.context_menu.create_room': 'Создать комнату',
    'rooms.code.enter_by_code': 'Вход по коду',
    'rooms.code.enter_code': 'Введите код комнаты',
    'rooms.code.join_btn': 'Войти',
    'rooms.create.create_room': 'Создание комнаты',
    'rooms.create.enter_name': 'Введите имя комнаты',
    'rooms.create.join_btn': 'Создать и войти',
    'rooms.room.empty_task': 'Нет активной задачи',
    'stats.average': 'Среднее',
    'timer.blocked': 'Без ограничений',
    'alert.leave_room.title': 'Подтверждение выхода',
    'alert.leave_room.success_btn': 'Покинуть',
    'alert.leave_room.description': 'Вы уверены что хотите покинуть комнату ?',
    'telegram.internal_error.title': 'Произошла неизвестная ошибка',
    'telegram.internal_error.description': 'Попробуйте пройти все шаги с самого сначала',
    'telegram.pending.title': 'Ожидается авторизация',
    'telegram.pending.description': 'Проверьте сообщение в "Telegram" для дальнейших шагов',
    'telegram.success.title': 'Вы успешно авторизованы',
    'telegram.success.description': 'Вы успешно авторизованы, при смене аккаунта процесс авторизации нужно будет пройти снова',
    'telegram.error.title': 'Вы не авторизованы',
    'telegram.error.description': 'Пожалуйста авторизуйтесь и нажмите на сообщение в "Telegram" снова',
  },
};
