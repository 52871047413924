import { createModel } from '@rematch/core';
import { RootModel } from "../rematch-models";
import { IUser } from "@scrum/shared-lib";

export interface ICommonState {
    currentUser: IUser | null;
}

export const user = createModel<RootModel>()({
    state: {
        currentUser: null,
    } as ICommonState,
    reducers: {
        setUser(state, user: IUser) {
            return {
                ...state,
                currentUser: user,
            }
        },
        clearUser(state) {
            return {
                ...state,
                currentUser: null,
            }
        }
    },
});
