export type IColors = { [key: string]: { [key: string]: boolean } };

export const USER_COLORS: IColors = {
    1: { '#C9512C': false },
    2: { '#C9B02C': false },
    3: { '#7AC92C': false },
    4: { '#2CC99A': false },
    5: { '#2C90C9': false },
    6: { '#C92C3F': false },
    7: { '#672CC9': false },
    8: { '#1C84FF': false },
    9: { '#D4380D': false },
    10: { '#32B305': false },
    11: { '#FA8C16': false },
    12: { '#C92C8A': false },
    13: { '#08979C': false },
    14: { '#9254DE': false },
    15: { '#2F54EB': false },
    16: { '#F759AB': false },
};

export const ARR_USER_COLORS = ['#C9512C', '#C9B02C', '#7AC92C', '#2CC99A', '#2C90C9', '#C92C3F', '#672CC9', '#1C84FF', '#D4380D', '#32B305', '#C92C8A', '#08979C', '#9254DE', '#2F54EB', '#F759AB']
