import * as FeatherIcons from 'react-feather';
import { COLORS } from '@scrum/shared-lib';

interface IProps {
  icon: keyof typeof FeatherIcons;
  size?: number | undefined;
  color?: string | undefined;
}

export const Icon = ({ icon, size = 26, color = COLORS.WHITE }: IProps) => {
  const Icon = FeatherIcons[icon];
  return <Icon {...{ size, color }} />;
};
