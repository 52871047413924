import SocketIoClient, { Socket } from "socket.io-client";
import { Subject } from "rxjs";
import { IRoom } from "./room";
import { ITask } from "./task";
import { IRoomUser, IUser } from "./user";
import { ISettings } from "./settings";
import { TRoomRole } from "./role";
import { ETaskStage } from "./stage";
import { IVote } from "./vote";
import { TRoomUser } from "./user-room";

export interface IEmitEvents {
  createTask: (data: { title: string; position: number; description?: string }) => void;
  deleteTask: (data: { taskId: string }) => void;
  updateTask: (data: { taskId: string, value: string }) => void;
  switchTask: (data: { taskId: string, stage: ETaskStage }) => void;
  resetTask: (data: { taskId: string, stage: ETaskStage }) => void;
  changeTaskStage: (data: { taskId: string; stage: number }) => void;
  addVote: (data: { value: number, taskId: string }) => void;
  removeVote: (data: { voteId: string, taskId: string, userId: string }) => void;
  updateUser: (data: { fields: Partial<IUser> }) => void;
  updateSettings: (data: { key: keyof ISettings, value: ISettings[keyof ISettings]}) => void;
  changeRole: (data: { userId: string; role: TRoomRole }) => void;
  changeWeight: (data: { weight: number }) => void;
  kickUser: (data: { userId: string }) => void;
  reloadUserPage: (data: { userId: string }) => void;
  reloadAll: () => void;
}

export interface IListenEvents {
  clientConnected: (data: {
    user: IRoomUser;
    room: IRoom,
    participants: IRoomUser[],
    tasks: ITask[],
    votes: {[taskId: string]: {[userId: string]: IVote}}
  }) => void;
  userConnected: (user: TRoomUser) => void;
  userDisconnected: (userId: string | string[]) => void;
  userUpdated: (user: IUser) => void;
  taskCreated: (task: ITask) => void;
  taskCreatedBatch: (data: { tasks: ITask[] }) => void;
  taskDeleted: IEmitEvents['deleteTask'];
  taskUpdated: IEmitEvents['updateTask'];
  taskSwitched: (task: ITask) => void;
  taskStaged: (task: ITask) => void;
  taskReseted: (data: { task: ITask }) => void;
  voteAdded: (data: { vote: IVote, taskId: string }) => void;
  voteRemoved: (data: { voteId: string, taskId: string, userId: string }) => void;
  settingsUpdated: (data: { setting: ISettings }) => void;
  roleChanged: IEmitEvents['changeRole']
  timeStarter: (data : { timeStartAt: string }) => void;
  weightChanged: IEmitEvents['changeWeight'];
  userKicked: IEmitEvents['kickUser'];
  reloadUserPage: IEmitEvents['reloadUserPage']
  reloadAll: IEmitEvents['reloadAll'];
}

export class AppSocket<T extends IListenEvents = IListenEvents, K extends keyof IListenEvents = keyof IListenEvents, E extends IEmitEvents = IEmitEvents, R extends keyof IEmitEvents = keyof IEmitEvents> {
  private socket!: Socket<IListenEvents, IEmitEvents>;
  public clientConnected$ = new Subject<boolean>();
  private _userId = '';
  private _roomId = '';

  public get userId() {
    return this._userId
  }
  public get roomId() {
    return this._roomId
  }

  public on<I extends T, P extends K>(action: P, cb: I[P]) {
    return this.socket.on(action, cb as any)
  }

  public send(event: R, ...data: Parameters<E[R]>) {
    this.socket.emit(event, ...data);
  }

  public removeEventListener<I extends T, P extends K>(action: P, cb: I[P]) {
    this.socket.removeListener(action, cb as any)
  }

  public connect(roomId: string, userId: string, url: string, secretKey: string) {
    return new Promise<void>((accept, failed) => {
      this._userId = userId;
      this._roomId = roomId;

      this.close();

      this.socket = SocketIoClient(url, {
        path: '/group-room',
        query: {
          userId,
          roomId,
        },
        auth: {
          token: secretKey,
        },
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 5000,
      });

      // DEFAULT EVENTS
      this.socket.on('connect', () => {
        console.log('connect');
        accept();
      });

      this.socket.on('connect_error', err => {
        console.log('connect_error', err);
        failed();
        this.clientConnected$.next(false);
      });

      this.socket.on('disconnect', () => {
        console.log('disconnect');
        failed();
        this.clientConnected$.next(false);
      });
    });
  }

  public close() {
    console.log('close');
    this._userId = '';
    this._roomId = '';
    this.clientConnected$.complete();

    if (this.socket?.connected) {
      this.socket.disconnect();
    }
  }
}
