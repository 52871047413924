import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { UserBar } from './user-bar/UserBar';
import { ActivityCard } from './activity-card/ActivityCard';
import { useTypedSelector } from '@hooks/typed-selector';
import { Controls } from './controls/Controls';
import { Tasks } from './tasks/Tasks';
import { Stats } from './results/Stats';
import { Cards } from './cards/Cards';
import { ETaskStage } from '@scrum/shared-lib';
import { useTransition, animated, useSpring, config } from 'react-spring';
import Lottie from 'react-lottie';
import './styles.sass';

export const Content = () => {
  const accentColor = useTypedSelector(({ common }) => common.roomColor);
  const currentTask = useTypedSelector(
    ({ scrumRoom: { tasks, room } }) => tasks.find(el => el.id === room?.currentTaskId),
    shallowEqual,
  );
  const showTasks = useTypedSelector(({ scrumRoom }) => !!scrumRoom.room?.settings.showTasks);
  const votes = useTypedSelector(
    ({ scrumRoom: { room, votes } }) => (room?.currentTaskId ? votes[room.currentTaskId] : null),
    shallowEqual,
  );
  const isLeading = useTypedSelector(({ scrumRoom }) => scrumRoom.currentUser?.role === 'leading');
  const currentUserId = useTypedSelector(({ scrumRoom }) => scrumRoom.currentUser?.id || '');

  const currentTaskId = currentTask?.id || '';
  const canShowTasks = isLeading || showTasks;
  const myVote = useMemo(
    () => (currentTask && votes && currentUserId ? votes[currentUserId] : undefined),
    [currentTask, votes, currentUserId],
  );

  const stage = currentTask?.stage;

  const transitions = useTransition(currentTask, {
    enter: { transform: 'translateX(0px)  scale(1)', opacity: 1 },
    from: { transform: 'translateX(500px) scale(0.7)', opacity: 0 },
    leave: {
      transform: 'translateX(-500px) scale(0.7)',
      opacity: 0,
      position: 'absolute',
    },
    keys: item => item?.id || '1',
  });

  const emptyTransition = useTransition(!currentTask, {
    enter: { transform: 'scale(1)', opacity: 1 },
    from: { transform: 'scale(0.7)', opacity: 0 },
    leave: { transform: 'scale(0)', opacity: 0, position: 'absolute' },
  });

  const tasksTransitions = useTransition(canShowTasks, {
    enter: { transform: 'translateX(0px)', opacity: 1, width: '17%', minWidth: '350px' },
    from: { transform: 'translateX(500px)', opacity: 0, width: '0%', minWidth: '0px' },
    leave: { transform: 'translateX(500px)', opacity: 0, width: '0%', minWidth: '0px' },
    config: config.stiff,
  });

  const mainStyles = useSpring({ width: canShowTasks ? '73%' : '80%' });

  return (
    <div className="container">
      <section className="users-container">
        <UserBar {...{ votes, stage }} />
      </section>
      <animated.section className="main-container" style={mainStyles}>
        <div className="main-container__content">
          {emptyTransition(
            (styles, canShow) =>
              canShow && (
                <animated.div style={styles} className="main-container__empty">
                  <Lottie
                    options={{ animationData: require('../../assets/animations/empty.json') }}
                  />
                </animated.div>
              ),
          )}
          {transitions(
            (styles, currentTask) =>
              currentTask && (
                <animated.div style={styles}>
                  <ActivityCard {...{ currentTask, accentColor, votes }}>
                    {isLeading && <Controls {...{ currentTask, accentColor }} />}
                  </ActivityCard>
                </animated.div>
              ),
          )}
        </div>
        {stage === ETaskStage.VOTING && (
          <Cards {...{ myVote, accentColor, currentTaskId, currentUserId }} />
        )}
        {stage === ETaskStage.RESULTS && votes && <Stats {...{ accentColor, votes }} />}
      </animated.section>

      {tasksTransitions(
        (styles, canShow) =>
          canShow && (
            <animated.section style={styles} className="tasks-container">
              <Tasks {...{ accentColor, currentTaskId, isLeading }} />
            </animated.section>
          ),
      )}
    </div>
  );
};
