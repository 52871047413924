import { AppButton } from '@components/app-button/AppButton';
import { memo, useCallback } from 'react';
import { COLORS, controlsTranslate, ETaskStage, ITask } from '@scrum/shared-lib';
import { useTypedSelector } from '@hooks/typed-selector';
import { SocketService } from '@services/AppSocket';
import { Utils } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import './styles.sass';
import * as FeatherIcons from 'react-feather';

interface IProps {
  currentTask: ITask;
  accentColor: string;
}

const icons: { [key: number]: keyof typeof FeatherIcons } = {
  [ETaskStage.NOT_STARTED]: 'Play',
  [ETaskStage.VOTING]: 'PieChart',
  [ETaskStage.RESULTS]: 'ChevronRight',
};
export const Controls = memo(({ currentTask, accentColor }: IProps) => {
  const tasks = useTypedSelector(({ scrumRoom }) => scrumRoom.tasks);
  const isResults = currentTask.stage === ETaskStage.RESULTS;
  const useFirstStage = useTypedSelector(
    ({ scrumRoom }) => !!scrumRoom.room?.settings.useFirstStage,
  );
  const { t } = useTranslation();

  const nextTask = Utils.getNextTask(tasks, currentTask.id);
  const isDisabled = isResults && !nextTask;

  const setNextStage = useCallback(() => {
    if (currentTask.stage === ETaskStage.NOT_STARTED) {
      SocketService.send('changeTaskStage', { taskId: currentTask.id, stage: ETaskStage.VOTING });
    } else if (currentTask.stage === ETaskStage.VOTING) {
      SocketService.send('changeTaskStage', { taskId: currentTask.id, stage: ETaskStage.RESULTS });
    } else {
      SocketService.send('switchTask', {
        taskId: nextTask.id,
        stage: useFirstStage ? ETaskStage.NOT_STARTED : ETaskStage.VOTING,
      });
    }
  }, [currentTask?.id, currentTask.stage, nextTask?.id, useFirstStage]);

  const resetTask = () => {
    SocketService.send('resetTask', {
      taskId: currentTask.id,
      stage: useFirstStage ? ETaskStage.NOT_STARTED : ETaskStage.VOTING,
    });
  };

  return (
    <div className="controls">
      {isResults && (
        <AppButton
          icon="RotateCcw"
          backgroundColor={accentColor}
          iconSize={20}
          width={48}
          marginRight={6}
          hoverColor={COLORS.convertHEXtoRGBA(accentColor, 0.7)}
          onClick={resetTask}
        />
      )}
      <AppButton
        text={t(controlsTranslate[currentTask.stage])}
        // icon={icons[currentTask.stage]}
        isDisabled={isDisabled}
        textColor={isDisabled ? COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.7) : COLORS.WHITE}
        backgroundColor={isDisabled ? COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.4) : accentColor}
        hoverColor={COLORS.convertHEXtoRGBA(accentColor, 0.7)}
        onClick={setNextStage}
      />
    </div>
  );
});
