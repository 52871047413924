import { IVote } from './vote';
import { ITask } from "./task";
import { ARR_USER_COLORS } from "./user-colors";

export class AppUtils {
    public sleep(delay = 1000) {
        return new Promise<void>(resolve => {
            setTimeout(() => {
                resolve();
            }, delay);
        });
    }

    public getNextTask(tasks:  ITask[], currentTaskId: string) {
        const currentTaskIndex = tasks.findIndex(el => el.id === currentTaskId);
        const nextTask = tasks[currentTaskIndex + 1];
        return nextTask || null;
    }

    public getRandomUserColor() {
        return ARR_USER_COLORS[Math.round(Math.random() * ARR_USER_COLORS.length)]
    }

    public nameSymbols(textA: string, textB?: string | null) {
        const a = textA[0].toUpperCase();
        const b = textB ? textB[0]?.toUpperCase() : '';
        return a + b;
    };

    public calculateTimerPercentage(duration: number, currentTime: number) {
        return Math.floor(((duration - currentTime) / duration) * 100);
    }

    public getDifferentTimerTime(startAt: string, duration: number) {
        const startDate = new Date(startAt);
        const finishedDate = startDate.setSeconds(startDate.getSeconds() + duration);
        const currentDate = new Date().getTime();
        const result = Math.ceil((finishedDate - currentDate) / 1000);
        return result <= 0 ? 0 : result
    }

    public formatTime(startSeconds: number) {
        const minutes = Math.floor(startSeconds / 60);
        const seconds = startSeconds % 60;
        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    public nameShortcut(textA: string, textB?: string | null) {
        const a = textA[0].toUpperCase() + textA.slice(1);
        const b = textB ? ` ${textB[0]?.toUpperCase()}` : '';
        return a + b;
    }

    public calculateAverage(votes: { [userId: string]: IVote }) {
        const votesArr = Object.values(votes);
        const votesCount = votesArr.length;
        const realVotes = votesArr.reduce((acc, item) => {
            if (item.value) {
                acc++
            }
            return acc;
        }, 0);

        const average = realVotes ? (votesArr.reduce((acc, vote) => acc + vote.value, 0) / realVotes).toFixed(0) : 0;

        const stats = votesArr.reduce<{ [key: number]: number }>((acc, item) => {
            if (acc[item.value]) {
                acc[item.value] += 1;
            } else {
                acc[item.value] = 1;
            }
            return acc;
        }, {});
        return { average, stats, votesCount };
    }

    public fakeRequest(delay = 1000, successChance = 0.8) {
        return new Promise<'SUCCESS'>((resolve, reject) => {
            setTimeout(() => {
                if (Math.random() < successChance) {
                    resolve('SUCCESS');
                } else {
                    reject('FAILED');
                }
            }, delay);
        });
    }
}
