import { FC, memo, useCallback, useEffect, useState } from 'react';
import { COLORS } from '@scrum/shared-lib';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { IToast } from './interfaces';
import { useTypedRxSelector } from '@hooks/typed-rx-selector';
import { delay, of, tap } from 'rxjs';
import './styles.sass';
import { useTranslation } from 'react-i18next';
import { config, useTransition, animated } from 'react-spring';

export const ToastProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);
  const toastService = useTypedRxSelector(({ common }) => common.toastService);

  const remove = useCallback((id: string) => {
    setToasts(prev => prev.filter(el => el.id !== id));
  }, []);

  useEffect(() => {
    const subscription$ = toastService
      .observer()
      .pipe(
        tap(toast => {
          if (toast.clearQueue) {
            setToasts([]);
          }
          return of(toast.id)
            .pipe(
              delay(toast.delay),
              tap(id => remove(id)),
            )
            .subscribe();
        }),
      )
      .subscribe(toast => {
        setToasts(prev => [...prev, toast]);
      });
    return () => {
      subscription$.unsubscribe();
    };
  }, [remove, toastService]);

  const transitions = useTransition(toasts, {
    enter: { opacity: 1, transform: 'translateY(0px)', height: 60 },
    from: { opacity: 0, transform: 'translateY(-100px)', height: 0 },
    leave: { opacity: 0, transform: 'translateY(-100px)', height: 0 },
    config: config.stiff,
    keys: item => item.id,
  });

  return (
    <div>
      {!!toasts.length && (
        <div className="toast-container">
          {transitions(
            (styles, item) =>
              item && (
                <animated.div key={item.id} style={styles}>
                  <Toast {...item} {...{ remove }} />
                </animated.div>
              ),
          )}
        </div>
      )}
      {children}
    </div>
  );
};

type ITaskProps = IToast & { remove: (id: string) => void };

const Toast = memo(({ id, text, type, remove }: ITaskProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="toast-container__item"
      style={{
        backgroundColor: COLORS.convertHEXtoRGBA(
          type === 'error' ? COLORS.RED : COLORS.GREEN_1,
          0.7,
        ),
      }}
    >
      <span>{t(text)}</span>
      <ClickableIcon icon="XCircle" iconColor={COLORS.GRAY_5} onClick={() => remove(id)} />
    </div>
  );
});
