import { memo, ReactNode, useEffect } from 'react';
import { config, useTransition, animated } from 'react-spring';
import './styles.sass';

interface IProps {
  isVisible: boolean;
  button?: ReactNode;
  closeMenu: () => void;
  children?: ReactNode;
}

export const DropDownMenu = memo(({ button, isVisible, closeMenu, children }: IProps) => {
  useEffect(() => {
    if (isVisible) {
      document.addEventListener('click', closeMenu);
    } else {
      document.removeEventListener('click', closeMenu);
    }
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [closeMenu, isVisible]);

  const transitions = useTransition(isVisible, {
    enter: { transform: 'perspective(300px) translateX(0px) rotateX(0deg) scale(1)', opacity: 1 },
    from: {
      transform: 'perspective(300px) translateX(60px) rotateX(90deg) scale(0.6)',
      opacity: 1,
    },
    leave: {
      transform: 'perspective(300px) translateX(0px) rotateX(90deg) scale(0.6)',
      opacity: 0,
    },
    config: config.stiff,
  });

  return (
    <div className="menu">
      {button}
      {transitions(
        (styles, canShow) =>
          canShow && (
            <animated.div className="menu__container" style={styles}>
              {children}
            </animated.div>
          ),
      )}
    </div>
  );
});
