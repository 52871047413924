import { createModel } from '@rematch/core';
import { RootModel } from "../rematch-models";

export interface ICommonState {
    roomColor: string;
    tasksColor: string;
}

export const common = createModel<RootModel>()({
    state: {
        roomColor: '#255A4A',
        tasksColor: '#0F5767',
    } as ICommonState,
    reducers: {
        setRoomColor(state, color: string) {
            return {
                ...state,
                roomColor: color,
            };
        },
        setTasksColor(state, color: string) {
            return {
                ...state,
                tasksColor: color,
            };
        },
    },
});
