import {
  ContextMenu as LibContextMenu,
  MenuItem,
  ContextMenuTrigger as LibContextMenuTrigger,
} from 'react-contextmenu';
import { Icon } from '@components/app-icon/Icon';
import { COLORS } from '@scrum/shared-lib';
import { ComponentProps, useCallback, useState } from 'react';
import './styles.sass';

interface IProps {
  id: string;
  buttons: TContextMenuButton[];
  isDisabled?: boolean;
}

type TIconProps = ComponentProps<typeof Icon>;
type TContextMenuButton = TIconProps & { text: string; textColor?: string; onClick?: () => void };

export const ContextMenuTrigger = LibContextMenuTrigger;
export const ContextMenu = ({ id, buttons, isDisabled = false }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenuVisible = useCallback(() => {
    if (!isDisabled) {
      setIsVisible(prev => !prev);
    }
  }, [isDisabled]);

  return (
    <LibContextMenu
      {...{ id }}
      className="contextmenu"
      onShow={toggleMenuVisible}
      onHide={toggleMenuVisible}
    >
      {isVisible && (
        <div
          className="container"
          style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7) }}
        >
          {buttons.map(button => (
            <MenuItem
              key={button.text + button.icon}
              className="contextmenu-item"
              onClick={button.onClick}
            >
              <div className="contextmenu-item__icon">
                <Icon {...button} />
              </div>
              <span style={{ color: button.textColor }}>{button.text}</span>
            </MenuItem>
          ))}
        </div>
      )}
    </LibContextMenu>
  );
};
