import './styles.sass';
import { memo, ReactNode } from 'react';
import { COLORS } from '@scrum/shared-lib';

interface IProps {
  title: string;
  titleColor?: string;
  useSeparation?: boolean;
  children?: ReactNode;
}
export const Section = memo(
  ({ title, titleColor = COLORS.YELLOW, useSeparation = true, children }: IProps) => {
    return (
      <section className="settings-section">
        <span className="settings-section__title" style={{ color: titleColor }}>
          {title}
        </span>
        {children}
        {useSeparation && (
          <div
            className="settings-section__separator"
            style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.WHITE, 0.22) }}
          />
        )}
      </section>
    );
  },
);
