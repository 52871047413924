import { Subject } from 'rxjs';
import { IToast } from '@components/toast-provider/interfaces';
import { v4 } from 'uuid';

export class ToastService {
  private emitter$ = new Subject<IToast>();
  private default: IToast = {
    id: v4(),
    text: 'Message',
    type: 'info',
    delay: 3000,
    clearQueue: false,
  };

  public observer() {
    return this.emitter$.pipe();
  }

  public show(toast: Partial<IToast>) {
    this.emitter$.next({ ...this.default, ...toast, id: v4() });
  }
}

export const commonReducer = {
  toastService: new ToastService(),
};
