import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Task } from '@pages/room/tasks/Task';
import { AppInput } from '@components/app-input/AppInput';
import { COLORS, ETaskStage, ITask } from '@scrum/shared-lib';
import { AppButton } from '@components/app-button/AppButton';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { SocketService } from '@services/AppSocket';
import { TRootState } from '@scrum/rematch-store';
import { Icon } from '@components/app-icon/Icon';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { animated, config, Transition } from 'react-spring';
import './styles.sass';

interface IProps extends WithTranslationProps {
  tasks: ITask[];
  useFirstStage: boolean;
  accentColor: string;
  currentTaskId: string;
  isLeading: boolean;
}

interface IState {
  value: string;
  editedTask: ITask | null;
}

const mainColor = COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.8);

class TasksComponent extends Component<IProps, IState> {
  private lastRef = createRef<HTMLDivElement>();
  private inputRef: HTMLInputElement | null = null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      value: '',
      editedTask: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (prevProps.tasks.length < this.props.tasks.length && this.props.isLeading) {
      this.lastRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }

  private setTextValue = (text: string) => {
    this.setState({ value: text });
  };

  private removeTask = (taskId: string) => {
    SocketService.send('deleteTask', { taskId });
  };

  private startTask = (taskId: string, currentStage: ETaskStage) => {
    const { useFirstStage } = this.props;
    const data: { taskId: string; stage?: ETaskStage } = { taskId };
    data.stage = useFirstStage
      ? currentStage
      : currentStage > ETaskStage.VOTING
      ? currentStage
      : ETaskStage.VOTING;

    SocketService.send('switchTask', data);
  };

  private editTask = (taskId: string) => {
    const { tasks } = this.props;
    const task = tasks.find(el => el.id === taskId);
    if (task) {
      this.setState({ editedTask: task, value: task.title });
      this.inputRef?.focus({ preventScroll: false });
    }
  };

  private closeEdit = () => {
    this.setState({ editedTask: null, value: '' });
  };

  private createTask = () => {
    const { value, editedTask } = this.state;
    const { tasks } = this.props;

    if (editedTask) {
      SocketService.send('updateTask', { taskId: editedTask.id, value });
      this.closeEdit();
    } else {
      SocketService.send('createTask', { title: value, position: tasks.length });
      this.setState({ value: '' });
    }
  };

  private setRef = (ref: HTMLInputElement | null) => {
    this.inputRef = ref;
  };

  private onKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.value.trim().length) {
      this.createTask();
    }
  };

  render(): JSX.Element {
    const { accentColor, tasks, i18n, currentTaskId, isLeading } = this.props;
    const { value, editedTask } = this.state;
    const { removeTask, editTask, startTask, setTextValue, createTask, closeEdit } = this;
    const isDisabled = !value.trim().length;

    return (
      <div className="tasks">
        <div className="tasks__content">
          <Transition
            trail={55}
            config={config.default}
            keys={tasks.map(el => el.id)}
            items={tasks}
            delay={10}
            enter={{
              from: { transform: 'translateX(-30px) scale(0.3)', opacity: 0 },
              to: [
                { transform: 'translateX(0px) scale(1)', opacity: 1 },
                { transform: 'none', immediate: true },
              ],
            }}
            leave={{
              from: { transform: 'translateX(0px) scale(1)', height: 100, opacity: 1 },
              to: { transform: 'translateX(0px) scale(0.3)', height: 0, opacity: 0 },
            }}
          >
            {(styles, item) => (
              <animated.div key={item.id} style={styles}>
                <Task
                  {...item}
                  isActive={currentTaskId === item.id}
                  viewOnly={!isLeading}
                  {...{ accentColor, removeTask, editTask, startTask }}
                />
              </animated.div>
            )}
          </Transition>
          <div ref={this.lastRef} />
        </div>
        <div className="tasks__footer">
          {editedTask && (
            <div className="tasks__edit" style={{ backgroundColor: mainColor }}>
              <Icon icon="Edit2" color={COLORS.GRAY_8} />
              <span>{editedTask.title}</span>
              <ClickableIcon
                icon="XCircle"
                iconColor={COLORS.convertHEXtoRGBA(COLORS.WHITE, 0.6)}
                onClick={closeEdit}
              />
            </div>
          )}
          {isLeading && (
            <>
              <AppInput
                value={value}
                getRef={this.setRef}
                placeholder={i18n?.t('tasks.input.placeholder')}
                onChangeText={setTextValue}
                backgroundColor={mainColor}
                placeholderColor={COLORS.GRAY_8}
                onKeyUp={this.onKeyUp}
                borderRadius="6px 0 0 6px"
              />
              <AppButton
                backgroundColor={mainColor}
                icon="Send"
                width={48}
                iconColor={!isDisabled ? COLORS.GRAY_6 : COLORS.GRAY_9}
                borderRadius="0 6px 6px 0"
                onClick={createTask}
                {...{ isDisabled }}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: TRootState) => ({
  tasks: state.scrumRoom.tasks,
  useFirstStage: !!state.scrumRoom.room?.settings.useFirstStage,
});

const ConnectedTasks = connect(mapStateToProps)(TasksComponent);
export const Tasks = withTranslation()(ConnectedTasks);
