import { LanguageDetectorAsyncModule } from 'i18next';
import { LANGUAGE_KEY } from '@constants/local-storage';

export const languageDetectorPlugin: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  detect: async function (callback: (lang: string) => void) {
    const storageLang = await localStorage.getItem(LANGUAGE_KEY);

    if (storageLang) {
      return callback(storageLang);
    }

    return callback('ru');
  },
  cacheUserLanguage: async function (lang: string) {
    await localStorage.setItem(LANGUAGE_KEY, lang);
  },
};
