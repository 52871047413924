import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { DropDownMenu } from '@components/drop-menu/DropDownMenu';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { Header } from '@components/header/Header';
import { useTypedSelector } from '@hooks/typed-selector';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/modal/Modal';
import { useNavigate } from 'react-router-dom';
import './styles.sass';

type THeaderProps = ComponentProps<typeof Header>;

interface IProps {
  openSettings: () => void;
}
export const RoomHeader = ({ openSettings }: IProps) => {
  const [dropIsVisible, setDropIsVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const roomName = useTypedSelector(({ scrumRoom }) => scrumRoom.room?.name || '');
  const roomCode = useTypedSelector(({ scrumRoom }) => scrumRoom.room?.registrationCode || '');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggleDropVisible = useCallback(() => {
    setDropIsVisible(prev => !prev);
  }, []);

  const toggleModalVisible = useCallback(() => {
    setModalVisible(prev => !prev);
  }, []);

  const leftButtons = useMemo<THeaderProps['leftButtons']>(
    () => [{ icon: 'Settings', onClick: openSettings }],
    [openSettings],
  );

  const leave = useCallback(() => {
    navigate('/rooms', { replace: true });
  }, []);

  return (
    <>
      <Header
        title={roomName}
        rightButton={
          <div className="room-header__buttons">
            <DropDownMenu
              isVisible={dropIsVisible}
              closeMenu={toggleDropVisible}
              button={<ClickableIcon icon="Share2" onClick={toggleDropVisible} marginRight={16} />}
            >
              <AppButton
                reverse
                icon="Copy"
                width="230px"
                borderRadius={0}
                paddingHorizontal={20}
                text={t('room.menu.copy_code')}
                textColor={COLORS.GRAY_6}
                iconColor={COLORS.GRAY_6}
                hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
                backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
                onClick={() => navigator.clipboard.writeText(roomCode)}
              />
              <AppButton
                reverse
                icon="Link"
                width="230px"
                borderRadius={0}
                paddingHorizontal={20}
                text={t('room.menu.copy_link')}
                textColor={COLORS.GRAY_6}
                iconColor={COLORS.GRAY_6}
                hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
                backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
                onClick={() => navigator.clipboard.writeText(window.location.href)}
              />
            </DropDownMenu>
            <ClickableIcon icon="LogOut" onClick={toggleModalVisible} />
          </div>
        }
        {...{ leftButtons }}
      />
      <Modal
        isVisible={modalVisible}
        closeModal={toggleModalVisible}
        title="alert.leave_room.title"
        description="alert.leave_room.description"
        successButton={
          <AppButton
            text={t('alert.leave_room.success_btn')}
            textColor={COLORS.YELLOW}
            marginRight={16}
            backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.6)}
            hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.9)}
            onClick={leave}
          />
        }
      />
    </>
  );
};
