import { AppApi } from '@scrum/shared-lib';

class Api extends AppApi {
  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    super(`${process.env.REACT_APP_BASE_URL!}/api/`, process.env.REACT_APP_SECRET_KEY!);
  }

  sendTelegramOffer(chatId: string) {
    try {
      return this.instance.post('/telegram/offer', { chatId });
    } catch (e) {
      throw e;
    }
  }
}

export const ApiService = new Api();
