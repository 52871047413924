import { Models, RematchDispatch, RematchRootState } from '@rematch/core';
import { common } from './models/common-model';
import { room } from './models/room-model';
import { user } from "./models/user-model";

export interface RootModel extends Models<RootModel> {
    scrumRoom: typeof room;
    common: typeof common;
    user: typeof user
}

export const rootModel: RootModel = { scrumRoom: room, common, user };

export type TDispatch = RematchDispatch<RootModel>;
export type TRootState = RematchRootState<RootModel>;
