import { useCallback, useState } from 'react';
import { Section } from '@pages/room/settings/section/Section';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '@hooks/typed-selector';
import { AppInput } from '@components/app-input/AppInput';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { SocketService } from '@services/AppSocket';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '@services/AuthService';
import './styles.sass';

const BACKGROUND = COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0.5);
const HOVER = COLORS.convertHEXtoRGBA(COLORS.WHITE, 0.08);

export const ProfileSection = () => {
  const user = useTypedSelector(({ scrumRoom }) => scrumRoom.currentUser);
  const [fname, setFname] = useState(user?.fname || '');
  const [lname, setLname] = useState(user?.lname || '');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const updateUser = () => {
    if (user) {
      SocketService.send('updateUser', { fields: { fname, lname } });
    }
  };

  const exit = useCallback(() => {
    AuthService.logout();
    navigate('/auth', { replace: true });
  }, [navigate]);

  const fnameDisabled = user?.fname === fname || !fname.trim().length;
  const lnameDisabled = user?.lname === lname;

  return (
    <Section title={t('settings.section.profile.title')} useSeparation={false}>
      <div className="profile">
        <div className="profile__field">
          <AppInput
            value={fname}
            placeholder={t('settings.section.profile.fname')}
            onChangeText={setFname}
            maxLength={12}
            marginRight={12}
            backgroundColor={BACKGROUND}
          />
          <AppButton
            icon="Check"
            width={48}
            iconColor={fnameDisabled ? COLORS.GRAY_8 : COLORS.YELLOW}
            isDisabled={fnameDisabled}
            backgroundColor={BACKGROUND}
            hoverColor={HOVER}
            onClick={updateUser}
          />
        </div>
        <div className="profile__field">
          <AppInput
            value={lname}
            placeholder={t('settings.section.profile.lname')}
            maxLength={20}
            onChangeText={setLname}
            backgroundColor={BACKGROUND}
            marginRight={12}
          />
          <AppButton
            icon="Check"
            width={48}
            backgroundColor={BACKGROUND}
            hoverColor={HOVER}
            iconColor={lnameDisabled ? COLORS.GRAY_8 : COLORS.YELLOW}
            isDisabled={lnameDisabled}
            onClick={updateUser}
          />
        </div>
        <AppButton
          width={150}
          icon="LogOut"
          backgroundColor={BACKGROUND}
          hoverColor={HOVER}
          textColor={COLORS.RED}
          iconColor={COLORS.RED}
          text={t('auth.leave')}
          onClick={exit}
        />
      </div>
    </Section>
  );
};
