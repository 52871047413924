import { Section } from '../section/Section';
import { useTranslation } from 'react-i18next';
import { ACCENT_COLORS } from '@scrum/shared-lib';
import { useDispatch } from 'react-redux';
import { TDispatch } from '@scrum/rematch-store';
import { useTypedSelector } from '@hooks/typed-selector';
import { useCallback } from 'react';
import { ColorPicker } from '@components/color-picker/ColorPicker';

export const ColorSection = () => {
  const roomColor = useTypedSelector(({ common }) => common.roomColor);
  const dispatch = useDispatch<TDispatch>();
  const { t } = useTranslation();

  const changeColor = useCallback(
    (color: string) => {
      localStorage.setItem('@accentColor', color);
      dispatch.common.setRoomColor(color);
    },
    [dispatch.common],
  );

  return (
    <Section title={t('settings.section.colors.title')}>
      <ColorPicker colors={ACCENT_COLORS} activeColor={roomColor} {...{ changeColor }} />
    </Section>
  );
};
