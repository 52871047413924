import { AppSocket } from '@scrum/shared-lib';
import { store } from '@store/store';
import { batch } from 'react-redux';

class AppSocketService extends AppSocket {
  public async connect(roomId: string, userId: string): Promise<void> {
    return new Promise(async accept => {
      await super.connect(
        roomId,
        userId,
        process.env.REACT_APP_BASE_URL!,
        process.env.REACT_APP_SECRET_KEY!,
      );

      // __COMMON
      this.on('clientConnected', data => {
        console.log('clientConnected', data);
        this.clientConnected$.next(true);

        const { user, participants, tasks, room, votes } = data;
        this.dispatch.scrumRoom.init({
          room,
          tasks,
          votes,
          currentUser: user,
          users: participants,
        });
        accept();
      });

      this.on('userConnected', user => {
        console.log('userConnected', user);
        this.dispatch.scrumRoom.addUser(user);
      });

      this.on('userDisconnected', data => {
        console.log('userDisconnected', data);

        if (Array.isArray(data)) {
          batch(() => {
            data.forEach(userId => {
              this.dispatch.scrumRoom.removeUser(userId);
            });
          });
        } else {
          this.dispatch.scrumRoom.removeUser(data);
        }
      });

      this.on('userUpdated', data => {
        console.log('userUpdated', data);
        this.dispatch.scrumRoom.updateUser(data);
      });

      this.on('settingsUpdated', data => {
        console.log('settingsUpdated', data);
        this.dispatch.scrumRoom.updateRoomSettings(data.setting);
      });

      //__TASK EVENTS
      this.on('taskCreated', task => {
        console.log('taskCreated', task);
        this.dispatch.scrumRoom.addTask(task);
      });

      this.on('taskCreatedBatch', data => {
        this.dispatch.scrumRoom.addTaskBatch(data.tasks);
      });

      this.on('taskDeleted', data => {
        console.log('taskDeleted', data);
        this.dispatch.scrumRoom.removeTask(data.taskId);
      });

      this.on('taskUpdated', data => {
        console.log('taskUpdated', data);
        this.dispatch.scrumRoom.updateTask(data);
      });

      this.on('taskSwitched', task => {
        console.log('taskSwitched', task);
        this.dispatch.scrumRoom.switchTask(task);
      });

      this.on('taskReseted', data => {
        console.log('taskReseted', data.task);
        this.dispatch.scrumRoom.resetTask(data.task);
      });

      this.on('taskStaged', data => {
        console.log('taskStaged', data);
        this.dispatch.scrumRoom.setTaskStage(data);
      });

      this.on('timeStarter', data => {
        console.log('timeStarter', data);
      });

      //__VOTE EVENTS
      this.on('voteAdded', data => {
        console.log('voteAdded', data);
        this.dispatch.scrumRoom.addVote(data);
      });

      this.on('voteRemoved', data => {
        console.log('voteRemoved', data);
        this.dispatch.scrumRoom.removeVote(data);
      });

      this.on('roleChanged', data => {
        console.log('roleChanged', data);
        this.dispatch.scrumRoom.changeUserRole(data);
      });

      this.on('reloadUserPage', data => {
        if (data.userId === this.userId) {
          location.reload();
        }
      });

      this.on('reloadAll', () => {
        location.reload();
      });
    });
  }

  public get store() {
    return store.getState();
  }

  public get dispatch() {
    return store.dispatch;
  }
}

export const SocketService = new AppSocketService();

declare global {
  interface Window {
    wsService: typeof SocketService;
  }
}

window.wsService = SocketService;
