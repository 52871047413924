import React, {
  ChangeEvent,
  InputHTMLAttributes,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import './styles.sass';
import { COLORS } from '@scrum/shared-lib';

interface IProps extends InputHTMLAttributes<unknown> {
  getRef?: (ref: HTMLInputElement | null) => void;
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  borderRadius?: string;
  backgroundColor?: string;
  placeholderColor?: string;
  onChangeText?: (text: string) => void;
}

export const AppInput = memo(
  ({
    value,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    onChangeText,
    maxLength,
    backgroundColor = COLORS.GRAY_10,
    borderRadius,
    getRef,
    placeholderColor = COLORS.GRAY_9,
    ...props
  }: IProps) => {
    const [focused, setFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      getRef?.(inputRef.current);
    }, [getRef, inputRef]);

    const onChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        if (maxLength) {
          if (text.length <= maxLength) {
            onChangeText?.(event.target.value);
          }
        } else {
          onChangeText?.(event.target.value);
        }
      },
      [maxLength, onChangeText],
    );

    useLayoutEffect(() => {
      if (inputRef.current) {
        inputRef.current.style.setProperty('--placeholder-color', placeholderColor);
      }
    }, [placeholderColor]);

    return (
      <div
        className="app-input"
        style={{
          marginTop,
          marginBottom,
          marginRight,
          marginLeft,
        }}
      >
        <input
          ref={inputRef}
          value={value}
          style={{
            border: `${focused ? COLORS.GRAY_9 : COLORS.TRANSPARENT} 2px solid`,
            backgroundColor,
            borderRadius,
          }}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
          {...{ onChange }}
          {...props}
        />
      </div>
    );
  },
);
