export const en = {
  translation: {
    'auth.code': 'Invite code',
    'auth.room_name': 'Room name',
    'auth.enter_name': 'Enter name',
    'auth.join': 'Join',
    'auth.leave': 'Leave',
    'app.seconds_1': 'seconds',
    'app.seconds_2': 'seconds',
    'app.seconds_5': 'seconds',
    'app.minutes_1': 'minutes',
    'app.minutes_2': 'minutes',
    'app.minutes_5': 'minutes',
    'app.cancel': 'Cancel',
    'title.first': 'Acquaintance',
    'title.voting': 'Vote',
    'title.results': 'Results',
    'controls.first': 'Start voting',
    'controls.voting': 'Go to results',
    'controls.results': 'Next task',
    'controls.empty_task': 'Not have next task',
    'task.menu.remove': 'Remove',
    'task.menu.edit_text': 'Edit text',
    'task.menu.edit_description': 'Edit description',
    'task.menu.add_description': 'Add description',
    'tasks.input.placeholder': 'Enter task text',
    'settings.section.common': 'Common',
    'settings.section.common.notification': 'Notification',
    'settings.section.common.anonymous': 'Anonymous vote',
    'settings.section.common.tasks': 'Display task list',
    'settings.section.tasks.title': 'Tasks',
    'settings.section.tasks.acquaintance': 'Acquaintance stage',
    'settings.section.timer.title': 'Timer',
    'settings.section.timer.use_timer': 'Use timer',
    'settings.section.timer.auto_finish': 'Automatic completion of voting',
    'settings.section.timer.other': 'Other',
    'settings.section.timer.minutes': 'minutes',
    'settings.section.timer.seconds': 'seconds',
    'settings.section.profile.title': 'Profile',
    'settings.section.profile.fname': 'First name',
    'settings.section.profile.lname': 'Last name',
    'settings.section.colors.title': 'Color scheme',
    'settings.section.avatar.title': 'Avatar color',
    'settings.section.colors.room': 'Room',
    'settings.section.colors.tasks': 'Tasks',
    'settings.section.lang.title': 'Interface language',
    'toast.error.invalid_code': 'Invalid room code',
    'toast.info.unsub_room': 'You have successfully unsubscribed from the room',
    'toast.info.remove_room': 'Room successfully deleted',
    'toast.info.copy_code': 'Code copied successfully',
    'toast.info.copy_link': 'Link copied successfully',
    'room.menu.copy_code': 'Copy code',
    'room.menu.copy_link': 'Copy link',
    'room.menu.mote_info': 'More',
    'room.menu.remove': 'Remove room',
    'room.menu.unsubscribe': 'Leave the room',
    'room.context_menu.set_role': 'Set leading role',
    'room.context_menu.remove_role': 'Remove leading role',
    'room.context_menu.kick_user': 'Kick user',
    'rooms.title': 'My rooms',
    'rooms.context_menu.enter_by_code': 'Enter by code',
    'rooms.context_menu.create_room': 'Create room',
    'rooms.code.enter_by_code': 'Enter by code',
    'rooms.code.enter_code': 'Enter room code',
    'rooms.code.join_btn': 'Join',
    'rooms.create.create_room': 'Create a room',
    'rooms.create.enter_name': 'Enter room name',
    'rooms.create.join_btn': 'Create and join',
    'rooms.room.empty_task': 'No active task',
    'stats.average': 'Average',
    'timer.blocked': 'With no restrictions',
    'alert.leave_room.title': 'Exit confirmation',
    'alert.leave_room.success_btn': 'Leave',
    'alert.leave_room.description': 'Are you sure you want to leave the room ?',
    'telegram.internal_error.title': 'An unknown error has occurred',
    'telegram.internal_error.description': 'Try to go through all the steps from the very beginning',
    'telegram.pending.title': 'Authorization in pending',
    'telegram.pending.description': 'Check telegram bot message for next steps',
    'telegram.success.title': 'Authorization success',
    'telegram.success.description': 'You are successfully authorized, if you change your account, you will need to go through the authorization process again',
    'telegram.error.title': 'You are not authorized',
    'telegram.error.description': 'Please authorization and click telegram message again',
  },
};
