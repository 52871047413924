import { FC } from 'react';
import { CardSVG } from '@components/svg/Card';
import './styles.sass';

export const CardsWrapper: FC = ({ children }) => {
  return (
    <div className="cards-wrapper">
      <div className="cards-wrapper__card card--left">
        <CardSVG />
      </div>
      <div className="cards-wrapper__card card--right">
        <CardSVG />
      </div>
      <div className="cards-wrapper__content">{children}</div>
    </div>
  );
};
