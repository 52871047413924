import i18n from 'i18next';
import { en, ru } from '@scrum/shared-lib';
import { initReactI18next } from 'react-i18next';
import { languageDetectorPlugin } from './languageDetectorPlugin';

i18n
  .use(initReactI18next)
  .use(languageDetectorPlugin)
  .init({
    react: {
      useSuspense: false,
    },
    compatibilityJSON: 'v3',
    resources: {
      en,
      ru,
    },
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
