import { Component, Fragment } from 'react';
import { ApiService } from '@services/AppApi';
import { Header } from '@components/header/Header';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { MainLoader } from '@components/main-loader/MainLoader';
import { RoomCard } from '@pages/rooms/RoomCard';
import { IRoomWithInfoAndTask } from '@pages/rooms/interfaces';
import { connect } from 'react-redux';
import { TRootState } from '@scrum/rematch-store';
import { DropDownMenu } from '@components/drop-menu/DropDownMenu';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { withTranslation, WithTranslationProps } from 'react-i18next';
import { CreationModals } from '@pages/rooms/CreationModals';
import { config, Transition, animated } from 'react-spring';
import { EmptyList } from '@components/empty-list/EmptyList';
import { RoomInfoModal } from '@pages/rooms/RoomInfoModal';
import './styles.sass';

type IProps = ReturnType<typeof mapStateToProps> & WithTranslationProps;

interface IState {
  isLoading: boolean;
  dropIsVisible: boolean;
  createRoomModal: boolean;
  joinCodeModal: boolean;
  roomInfoId: string | null;
  settingsOpened: boolean;
  rooms: IRoomWithInfoAndTask[];
}

export class RoomsPageComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      dropIsVisible: false,
      createRoomModal: false,
      joinCodeModal: false,
      settingsOpened: false,
      roomInfoId: null,
      rooms: [],
    };
  }

  public async componentDidMount() {
    const res = await ApiService.getRooms();
    console.log('ROOMS: ', res);
    const rooms = res.rooms.map(el => {
      const userInfo = res.userInfos.find(info => info.roomId === el.id)!;
      const lastTask = res.lastTasks.find(task => task.roomId === el.id) || null;
      return {
        ...el,
        userInfo,
        lastTask,
      };
    });
    console.log('rooms', rooms);
    this.setState({ isLoading: false, rooms });
  }

  public toggleDropIsVisible = () => {
    this.setState(prev => ({ dropIsVisible: !prev.dropIsVisible }));
  };

  public toggleCreateRoomModal = () => {
    this.setState(prev => ({ createRoomModal: !prev.createRoomModal }));
  };

  public toggleJoinCodeModal = () => {
    this.setState(prev => ({ joinCodeModal: !prev.joinCodeModal }));
  };

  public toggleSettingsOpened = () => {
    this.setState(prev => ({ settingsOpened: !prev.settingsOpened }));
  };

  public openRoomInfoModal = (roomId: string) => {
    this.setState({ roomInfoId: roomId });
  };

  public closeRoomInfoModal = () => {
    this.setState({ roomInfoId: null });
  };

  public removeRoomFromList = (roomId: string) => {
    this.setState(prev => ({ rooms: prev.rooms.filter(el => el.id !== roomId) }));
  };

  render() {
    const { isLoading, rooms, dropIsVisible, joinCodeModal, createRoomModal } = this.state;
    const { roomInfoId } = this.state;
    const { toggleCreateRoomModal, toggleJoinCodeModal, toggleDropIsVisible } = this;
    const { removeRoomFromList, openRoomInfoModal, closeRoomInfoModal } = this;
    const { currentUserId, i18n } = this.props;

    return (
      <div className="rooms-wrapper">
        <Header
          title={i18n?.t('rooms.title')}
          // leftButtons={[{ icon: 'Settings' }]}
          rightButton={
            <DropDownMenu
              isVisible={dropIsVisible}
              closeMenu={toggleDropIsVisible}
              button={<ClickableIcon icon="Plus" onClick={toggleDropIsVisible} />}
            >
              <AppButton
                reverse
                icon="Grid"
                width="230px"
                borderRadius={0}
                paddingHorizontal={20}
                text={i18n?.t('rooms.context_menu.enter_by_code')}
                textColor={COLORS.GRAY_6}
                iconColor={COLORS.GRAY_6}
                hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
                backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
                onClick={this.toggleJoinCodeModal}
              />
              <AppButton
                reverse
                icon="PlusCircle"
                width="230px"
                borderRadius={0}
                paddingHorizontal={20}
                text={i18n?.t('rooms.context_menu.create_room')}
                textColor={COLORS.GRAY_6}
                iconColor={COLORS.GRAY_6}
                hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
                backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.7)}
                onClick={this.toggleCreateRoomModal}
              />
            </DropDownMenu>
          }
        />
        <RoomInfoModal {...{ roomInfoId, closeRoomInfoModal }} />
        <CreationModals
          {...{
            joinCodeModal,
            createRoomModal,
            toggleCreateRoomModal,
            currentUserId,
            toggleJoinCodeModal,
          }}
        />
        <div className="rooms">
          {isLoading ? (
            <MainLoader />
          ) : rooms.length ? (
            <Fragment>
              <Transition
                trail={105}
                config={config.stiff}
                items={rooms}
                delay={10}
                enter={{
                  from: { transform: 'translateX(-100px) scale(0.3)', opacity: 0 },
                  to: [
                    { transform: 'translateX(0px) scale(1)', opacity: 1 },
                    { transform: 'none', immediate: true },
                  ],
                }}
                leave={{
                  from: { transform: 'translateY(0px) scale(1)', width: 310, opacity: 1 },
                  to: { transform: 'translateY(-50px) scale(0.3)', width: 0, opacity: 0 },
                }}
              >
                {(styles, item) => (
                  <animated.div style={styles}>
                    <RoomCard
                      key={item.id}
                      {...item}
                      {...{ currentUserId, removeRoomFromList, openRoomInfoModal }}
                    />
                  </animated.div>
                )}
              </Transition>
            </Fragment>
          ) : (
            <EmptyList
              animationData={require('../../assets/animations/baby-waiting.json')}
              width={300}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }: TRootState) => ({
  currentUserId: user.currentUser?.id || '',
});

export const RoomsPage = connect(mapStateToProps)(withTranslation()(RoomsPageComponent));
