import Lottie from 'react-lottie';
import './styles.sass';

export const MainLoader = () => {
  return (
    <div className="loader">
      <div className="loader__content">
        <Lottie options={{ animationData: require('../../assets/animations/main-spinner.json') }} />
      </div>
    </div>
  );
};
