import { Icon } from '@components/app-icon/Icon';
import { COLORS } from '@scrum/shared-lib';
import { animated, config, useSpring } from 'react-spring';
import './styles.sass';

interface IProps {
  count: number;
  maxCount: number;
}

export const VotingProgress = ({ count, maxCount }: IProps) => {
  const color = count === maxCount ? COLORS.YELLOW : COLORS.GRAY_7;
  const iconSpring = useSpring({
    to: { transform: 'scale(1)' },
    from: { transform: 'scale(0)' },
    config: config.gentle,
  });

  const textSpring = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.stiff,
  });

  return (
    <div className="progress">
      <animated.div style={{ ...iconSpring, height: 32 }}>
        <Icon icon="CheckCircle" size={32} {...{ color }} />
      </animated.div>
      <animated.span className="progress__text" style={{ ...textSpring, color }}>
        {count}/{maxCount}
      </animated.span>
    </div>
  );
};
