import { animated, useTransition } from 'react-spring';
import { Icon } from '@components/app-icon/Icon';
import { memo } from 'react';
import './styles.sass';

interface IProps {
  colors: string[];
  activeColor: string;
  changeColor: (color: string) => void;
}

export const ColorPicker = memo(({ colors, activeColor, changeColor }: IProps) => {
  const transitions = useTransition(activeColor, {
    enter: { transform: 'scale(1)' },
    from: { transform: 'scale(0)' },
    leave: { transform: 'scale(0)' },
  });

  return (
    <div className="colors">
      {colors.map(el => (
        <div
          key={el}
          className="colors__item"
          style={{ backgroundColor: el }}
          onClick={() => changeColor(el)}
        >
          {transitions(
            (styles, color) =>
              el === color && (
                <animated.div style={{ ...styles, height: '20px' }}>
                  <Icon icon="CheckCircle" size={20} />
                </animated.div>
              ),
          )}
        </div>
      ))}
    </div>
  );
});
