import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthPage } from '@pages/auth/AuthPage';
import { RoomPage } from '@pages/room/RoomPage';
import { SplashPage, withSplashScreen } from '@pages/splash/SplashPage';
import { EmptyPage } from '@pages/empty/EmptyPage';
import { RoomsPage } from '@pages/rooms/RoomsPage';
import { CardsWrapper } from '@components/cards-wrapper/CardsWrapper';
import { ApiService } from '@services/AppApi';
import { AuthService } from '@services/AuthService';
import { TelegramPage } from '@pages/telegram/TelegramPage';

export const MainRouterSwitch = () => {
  useEffect(() => {
    const token = AuthService.getAccessToken();

    if (token) {
      ApiService.setAccessToken(token);
    }
    console.log('RENDER');
  }, []);

  return (
    <CardsWrapper>
      <BrowserRouter>
        <AppRoutesSplash />
      </BrowserRouter>
    </CardsWrapper>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<SplashPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/room/:id" element={<RoomPage />} />
      <Route path="/rooms" element={<RoomsPage />} />
      <Route path="/telegram/:chatId" element={<TelegramPage />} />
      <Route path="*" element={<EmptyPage />} />
    </Routes>
  );
};

const AppRoutesSplash = withSplashScreen(AppRoutes);
