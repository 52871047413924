export enum EAuthMessage {
  INCORRECT_EMAIL = 'INCORRECT_EMAIL',
  INCORRECT_PASS_LENGTH = 'INCORRECT_PASS_LENGTH',
  INCORRECT_USER_CREDENTIAL = 'INCORRECT_USER_CREDENTIAL',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
  CODE_SENT_SUCCESSFULLY = 'CODE_SENT_SUCCESSFULLY',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  ACCESS_ERROR = 'ACCESS_ERROR',
  ROOM_REMOVED = 'ROOM_REMOVED',
  ROOM_UNSUBSCRIBED = 'ROOM_UNSUBSCRIBED',
  ROOM_NOT_FOUND = 'ROOM_NOT_FOUND',
  CODE_SUCCESSFUL = 'CODE_SUCCESSFUL',
  SUBSCRIBE_SUCCESSFUL = 'SUBSCRIBE_SUCCESSFUL',
  ENTER_CORRECT_EMAIL = 'ENTER_CORRECT_EMAIL',
  ENTER_CORRECT_PASSWORD = 'ENTER_CORRECT_PASSWORD',
  AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INCORRECT_VERIFY_CODE = 'INCORRECT_VERIFY_CODE',
  VERIFY_CODE_EXPIRED = 'VERIFY_CODE_EXPIRED',
  CROOKED_REFRESH_TOKEN = 'CROOKED_REFRESH_TOKEN',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
  TOKEN_IS_VALID = 'TOKEN_IS_VALID',
  JWT_TOKEN_EXPIRED = 'JWT_TOKEN_EXPIRED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  BAD_REQUEST = 'BAD_REQUEST',
}
