import { COLORS } from '@scrum/shared-lib';
import { TailSpin } from 'react-loader-spinner';
import { FC } from 'react';

interface IProps {
  size?: number;
  color?: string;
}

export const Spinner: FC<IProps> = ({ size = 35, color = COLORS.YELLOW }) => {
  return <TailSpin radius={0} width={size} {...{ color }} />;
};
