import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '@services/AuthService';
import { useDispatch } from 'react-redux';
import { TDispatch } from '@scrum/rematch-store';
import { useTypedSelector } from '@hooks/typed-selector';
import Lottie from 'react-lottie';
import './styles.sass';

export const SplashPage = () => {
  const navigate = useNavigate();
  const logout = () => {
    AuthService.logout();
    navigate('auth', { replace: true });
  };

  useEffect(() => {
    AuthService.login()
      .then(resp => {
        if (resp.message === 'AUTHORIZATION_SUCCESS') {
          const { user } = resp;
          const roomId = AuthService.getRoom();

          if (user && roomId) {
            navigate(`room/${roomId}`, { state: { userId: user.id }, replace: true });
          } else if (user) {
            navigate('/rooms', { replace: true });
          } else {
            logout();
          }
        } else {
          logout();
        }
      })
      .catch(() => {
        logout();
      });
  }, []);

  return (
    <div className="splash">
      <Lottie
        width={350}
        height={350}
        options={{ animationData: require('../../assets/animations/main-spinner.json') }}
      />
    </div>
  );
};

export const withSplashScreen = <T extends object>(
  WrappedComponent: React.ComponentType<T & object>,
) => {
  return (props: T) => {
    const [isLoading, setIsLoading] = useState(true);
    const userId = useTypedSelector(({ user }) => user.currentUser?.id || '');
    const navigate = useNavigate();
    const dispatch = useDispatch<TDispatch>();
    const location = useLocation();
    console.log('location', location);
    const isRoomRoute = new RegExp(/\b(room)\b/i).test(location.pathname);
    const isTelegramRoute = new RegExp(/\b(telegram)\b/i).test(location.pathname);

    const logout = () => {
      setIsLoading(false);
      AuthService.logout();
      navigate('auth', { replace: true });
    };

    useEffect(() => {
      console.log('Splashing');

      if (isTelegramRoute) {
        console.log('REDIRECT TELEGRAM', location.pathname);
        setIsLoading(false);
        navigate(location.pathname, { replace: true });
        return;
      }

      if (!userId) {
        console.log('Check auth');
        AuthService.login()
          .then(resp => {
            if (resp.message === 'AUTHORIZATION_SUCCESS') {
              dispatch.user.setUser(resp.user);
              setIsLoading(false);
            } else {
              if (isRoomRoute) {
                const roomId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
                setIsLoading(false);
                navigate('auth', { state: { roomId }, replace: true });
              } else {
                logout();
              }
            }
          })
          .catch(() => {
            logout();
          });
      }
    }, [userId]);

    return <Fragment>{!isLoading && <WrappedComponent {...props} />}</Fragment>;
  };
};
