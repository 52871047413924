import Lottie from 'react-lottie';
import './styles.sass';

interface IProps {
  animationData: string;
  width?: string | number;
  height?: string | number;
}

export const EmptyList = ({ width, height, animationData }: IProps) => {
  return (
    <div className="empty-list">
      <Lottie options={{ animationData }} style={{ width, height }} />
    </div>
  );
};
