import { ComponentProps, FC, ReactNode } from 'react';
import { COLORS } from '@scrum/shared-lib';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import './styles.sass';

interface IProps {
  title?: string;
  backgroundColor?: string;
  leftButtons?: TClickableIconProps[];
  rightButtons?: TClickableIconProps[];
  rightButton?: ReactNode;
}

type TClickableIconProps = ComponentProps<typeof ClickableIcon>;

const defaultBackground = COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.6);

export const Header: FC<IProps> = ({
  backgroundColor = defaultBackground,
  title,
  rightButtons,
  leftButtons,
  rightButton,
}) => {
  return (
    <header className="header" style={{ backgroundColor }}>
      {leftButtons?.map(button => (
        <ClickableIcon key={button.icon} {...button} />
      ))}
      {title && (
        <h1 style={{ marginLeft: leftButtons?.length || rightButton ? 18 : 0 }}>{title}</h1>
      )}
      <div style={{ marginLeft: 'auto' }}>
        {rightButtons?.map(button => (
          <ClickableIcon key={button.icon} {...button} />
        ))}
        {rightButton}
      </div>
    </header>
  );
};
