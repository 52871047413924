import { FC, useMemo } from 'react';
import { COLORS, IVote } from '@scrum/shared-lib';
import { Utils } from '@utils/utils';
import { useTranslation } from 'react-i18next';
import { useTransition, animated, config } from 'react-spring';
import './styles.sass';

interface IProps {
  accentColor: string;
  votes: { [userId: string]: IVote };
}

const backgroundColor = COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.9);

export const Stats: FC<IProps> = ({ votes, accentColor }) => {
  const { t } = useTranslation();
  const { average, stats, votesCount } = useMemo(() => Utils.calculateAverage(votes), [votes]);
  const statsData = Object.entries(stats);

  const transitionsItems = useTransition(statsData, {
    enter: { transform: 'translateY(0px) scale(1)', opacity: 1 },
    from: { transform: 'translateY(70px) scale(0.3)', opacity: 0 },
    trail: 55,
    config: config.gentle,
    keys: ([key]) => key,
  });

  const transitionAverage = useTransition(average, {
    enter: { transform: 'translateX(0px) scale(1)', opacity: 1 },
    from: { transform: 'translateX(-100px) scale(1)', opacity: 0 },
    config: config.gentle,
  });

  return (
    <div className="stats">
      {transitionAverage(styles => (
        <animated.div className="average" style={{ backgroundColor, ...styles }}>
          <div className="average__radius" style={{ borderColor: accentColor }}>
            <span className="average__value">{average}</span>
          </div>
          <span className="average__title">{t('stats.average')}</span>
        </animated.div>
      ))}
      <div className="stats__results">
        {transitionsItems((styles, [key, value]) => (
          <animated.div style={styles}>
            <div className="stats__card" style={{ backgroundColor }}>
              <span>{+key > 0 ? key : '?'}</span>
              <div
                className="stats__progress"
                style={{ height: `${(value / votesCount) * 100}%`, backgroundColor: accentColor }}
              />
            </div>
          </animated.div>
        ))}
      </div>
    </div>
  );
};
