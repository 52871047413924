import { useCallback } from 'react';
import { animated, useTransition } from 'react-spring';
import { COLORS, ISettings } from '@scrum/shared-lib';
import { SocketService } from '@services/AppSocket';
import { CommonSection } from './common-section/CommonSection';
import { TaskSection } from './task-section/TaskSection';
import { TimerSection } from './timer-section/TimerSection';
import { ColorSection } from './color-section/ColorSection';
import { ProfileSection } from './profile-section/ProfileSection';
import { LangSection } from './lang-section/LangSection';
import { useTypedSelector } from '@hooks/typed-selector';
import { AvatarSection } from '@pages/room/settings/avatar-section/AvatarSection';
import './styles.sass';

interface IProps {
  isOpened: boolean;
  closeSettings: () => void;
}

export const Settings = ({ isOpened, closeSettings }: IProps) => {
  const changeSettings = useCallback((key: keyof ISettings, value: ISettings[keyof ISettings]) => {
    SocketService.send('updateSettings', { key, value });
  }, []);
  const user = useTypedSelector(({ scrumRoom }) => scrumRoom.currentUser);

  const contentTransitions = useTransition(isOpened, {
    enter: { x: 0 },
    from: { x: -window.innerWidth / 4 },
    leave: { x: -window.innerWidth / 4 },
  });

  const backDropTransitions = useTransition(isOpened, {
    from: {
      backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0),
    },
    enter: {
      backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.3),
    },
    leave: {
      backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0),
    },
    config: {
      duration: 250,
    },
    delay: 100,
  });

  return contentTransitions((props, item) =>
    item ? (
      <div className="settings">
        <animated.div className="settings__container" style={props}>
          <div
            className="settings__content"
            style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.6) }}
          >
            {user?.role === 'leading' && (
              <>
                <CommonSection {...{ changeSettings }} />
                <TaskSection {...{ changeSettings }} />
                <TimerSection {...{ changeSettings }} />
              </>
            )}
            <LangSection />
            <ColorSection />
            <AvatarSection />
            <ProfileSection />
          </div>
        </animated.div>
        {backDropTransitions(
          (props, item) =>
            item && (
              <animated.div className="settings__backdrop" style={props} onClick={closeSettings}>
                <div />
              </animated.div>
            ),
        )}
      </div>
    ) : null,
  );
};
