import { Component } from 'react';
import { CardSVG } from '@components/svg/Card';
import { AppInput } from '@components/app-input/AppInput';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS, IUser } from '@scrum/shared-lib';
import { ApiService } from '@services/AppApi';
import { AuthService } from '@services/AuthService';
import { useTranslation, WithTranslation } from 'react-i18next';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useTypedRxSelector } from '@hooks/typed-rx-selector';
import { ToastService } from '../../rx-store/reducers/common';
import './styles.sass';
import { useDispatch } from 'react-redux';
import { TDispatch } from '@scrum/rematch-store';

interface IState {
  roomName: string;
  roomCode: string;
  userName: string;
  isCreatingName: boolean;
  isCodeLoading: boolean;
  isJoinLoading: boolean;
}

interface IProps {
  navigate: NavigateFunction;
  toastService: ToastService;
  t: WithTranslation['t'];
  roomId: string | undefined;
  setUser: (user: IUser) => void;
}

class AuthPageComponent extends Component<IProps, IState> {
  private joinType: 'room' | 'code' = 'room';
  private userId = '';
  private roomId = '';

  constructor(props: IProps) {
    super(props);

    this.state = {
      roomCode: '',
      roomName: '',
      userName: '',
      isCreatingName: false,
      isJoinLoading: false,
      isCodeLoading: false,
    };
  }

  public componentDidMount() {
    const { roomId } = this.props;

    if (roomId) {
      this.roomId = roomId;
      this.joinType = 'code';
      this.setState({ isCreatingName: true });
    }
  }

  private join = () => {
    if (this.joinType === 'room') {
      this.createRoom();
    } else {
      this.joinInRoom();
    }
  };

  private getRoomByCode = async () => {
    const { roomCode } = this.state;

    this.setState({ isCodeLoading: true });
    const { toastService } = this.props;
    const data = await ApiService.getRoomByCode(roomCode);

    if (data.message === 'CODE_SUCCESSFUL') {
      this.roomId = data.roomId;
      this.setState({ isCreatingName: true });
    } else {
      toastService.show({ text: 'toast.error.invalid_code', type: 'error' });
    }
    this.setState({ isCodeLoading: false });
  };

  public createRoom = async () => {
    const { userName, roomName } = this.state;

    this.setState({ isJoinLoading: true });

    const data = await ApiService.createFakeUser(userName);
    const room = await ApiService.createRoom(roomName, data.user.id);

    await this.handleLoginData(data.user, room.roomId, data.accessToken);
    this.props.setUser(data.user);
    this.setState({ isJoinLoading: false });

    this.goToRoom();
  };

  private joinInRoom = async () => {
    this.setState({ isJoinLoading: true });
    const { userName } = this.state;

    const data = await ApiService.createFakeUser(userName);

    await this.handleLoginData(data.user, this.roomId, data.accessToken);

    this.props.setUser(data.user);
    this.setState({ isJoinLoading: false });

    this.goToRoom();
  };

  private handleLoginData = async (user: IUser, roomId: string, accessToken: string) => {
    this.userId = user.id;
    this.roomId = roomId;

    await AuthService.setRoom(roomId);
    await AuthService.setAccessToken(accessToken);
  };

  private submitRoomCode = async () => {
    this.joinType = 'code';
    this.getRoomByCode();
  };

  private submitRoomName = () => {
    this.joinType = 'room';
    this.setState({ isCreatingName: true });
  };

  private setRoomCode = (text: string) => {
    this.setState({ roomCode: text });
  };

  private setRoomName = (text: string) => {
    this.setState({ roomName: text });
  };

  private setUserName = (text: string) => {
    this.setState({ userName: text });
  };

  private goToRoom = () => {
    this.props.navigate(`/room/${this.roomId}`, { replace: true });
  };

  private goBack = () => {
    this.setState({ isCreatingName: false });
  };

  render() {
    const { roomName, roomCode, userName, isCreatingName, isCodeLoading } = this.state;
    const { t } = this.props;

    const isValidName = !!roomName.trim().length;
    const isValidUserName = !!userName.trim().length;
    const isValidCode = roomCode.length === 6;

    return (
      <div className="auth">
        <div className="card card--left">
          <CardSVG />
        </div>
        <div className="card card--right">
          <CardSVG />
        </div>
        <div className="auth__content">
          <div className="auth__logo">
            <span className="auth__logo-title">welcome to</span>
            <span className="auth__logo-subtitle">Pokerfy</span>
          </div>
          {!isCreatingName ? (
            <div key="1" className="auth__wrapper--first">
              <div className="auth__fields">
                <AppInput
                  value={roomCode}
                  type="number"
                  marginBottom={16}
                  maxLength={6}
                  placeholder={t('auth.code')}
                  marginRight={16}
                  onChangeText={this.setRoomCode}
                />
                <AppButton
                  icon="ArrowRight"
                  width={52}
                  iconColor={isValidCode ? COLORS.YELLOW : COLORS.GRAY_8}
                  isLoading={isCodeLoading}
                  isDisabled={!isValidCode}
                  onClick={this.submitRoomCode}
                />
              </div>
              <div className="auth__fields">
                <AppInput
                  value={roomName}
                  placeholder={t('auth.room_name')}
                  type="text"
                  maxLength={18}
                  marginRight={18}
                  onChangeText={this.setRoomName}
                />
                <AppButton
                  isDisabled={!isValidName}
                  icon="Plus"
                  width={52}
                  iconColor={isValidName ? COLORS.YELLOW : COLORS.GRAY_8}
                  onClick={this.submitRoomName}
                />
              </div>
            </div>
          ) : (
            <div key="2" className="auth__wrapper--second">
              <AppInput
                value={userName}
                placeholder={t('auth.enter_name')}
                type="text"
                maxLength={12}
                marginRight={18}
                marginBottom={16}
                onChangeText={this.setUserName}
              />
              <div className="auth__buttons">
                <AppButton
                  icon="ArrowLeft"
                  iconColor={COLORS.YELLOW}
                  marginRight={16}
                  width={52}
                  onClick={this.goBack}
                />
                <div style={{ width: '100%' }}>
                  <AppButton
                    isDisabled={!isValidUserName}
                    text={t('auth.join')}
                    icon="LogIn"
                    iconColor={isValidUserName ? COLORS.BLACK : COLORS.GRAY_8}
                    textColor={isValidUserName ? COLORS.BLACK : COLORS.GRAY_8}
                    hoverColor={
                      isValidUserName ? COLORS.convertHEXtoRGBA(COLORS.YELLOW, 0.7) : COLORS.GRAY_10
                    }
                    backgroundColor={isValidUserName ? COLORS.YELLOW : COLORS.GRAY_10}
                    onClick={this.join}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export const AuthPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as { roomId?: string };
  const dispatch = useDispatch<TDispatch>();
  const toastService = useTypedRxSelector(({ common }) => common.toastService);
  return (
    <AuthPageComponent
      {...{ navigate, toastService, t }}
      setUser={dispatch.user.setUser}
      roomId={locationState?.roomId}
    />
  );
};
