import {ETaskStage} from "./stage";

export const controlsTranslate = {
  [ETaskStage.NOT_STARTED]: 'controls.first',
  [ETaskStage.VOTING]: 'controls.voting',
  [ETaskStage.RESULTS]: 'controls.results',
};

export const titleTranslate = {
  [ETaskStage.NOT_STARTED]: 'title.first',
  [ETaskStage.VOTING]: 'title.voting',
  [ETaskStage.RESULTS]: 'title.results',
};
