import { Fragment, useCallback, useState } from 'react';
import { Modal } from '@components/modal/Modal';
import { AppButton } from '@components/app-button/AppButton';
import { COLORS } from '@scrum/shared-lib';
import { AppInput } from '@components/app-input/AppInput';
import { useTranslation } from 'react-i18next';
import { ApiService } from '@services/AppApi';
import { useNavigate } from 'react-router-dom';
import { useTypedRxSelector } from '@hooks/typed-rx-selector';
import './styles.sass';

interface IProps {
  createRoomModal: boolean;
  joinCodeModal: boolean;
  currentUserId: string;
  toggleCreateRoomModal: () => void;
  toggleJoinCodeModal: () => void;
}

export const CreationModals = ({
  createRoomModal,
  joinCodeModal,
  currentUserId,
  toggleJoinCodeModal,
  toggleCreateRoomModal,
}: IProps) => {
  const [roomCode, setRoomCode] = useState('');
  const [roomName, setRoomName] = useState('');
  const toastService = useTypedRxSelector(({ common }) => common.toastService);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const createRoomAndJoin = async () => {
    const resp = await ApiService.createRoom(roomName, currentUserId);
    goToRoom(resp.roomId, currentUserId);
  };

  const joinByCode = async () => {
    const data = await ApiService.getRoomByCode(roomCode);
    if (data.message === 'CODE_SUCCESSFUL') {
      goToRoom(data.roomId, currentUserId);
    } else {
      toastService.show({ text: 'toast.error.invalid_code', type: 'error' });
    }
  };

  const goToRoom = useCallback(
    (roomId: string, userId: string) => {
      navigate(`/room/${roomId}`, { state: { userId } });
    },
    [navigate],
  );

  const codeDisabled = !roomCode.trim().length;
  const createDisabled = !roomName.trim().length;

  return (
    <Fragment>
      <Modal
        isVisible={joinCodeModal}
        title={t('rooms.code.enter_by_code')}
        closeModal={toggleJoinCodeModal}
        successButton={
          <AppButton
            text={t('rooms.code.join_btn')}
            textColor={COLORS.YELLOW}
            marginRight={16}
            backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, codeDisabled ? 0.3 : 0.6)}
            hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.9)}
            isDisabled={codeDisabled}
            onClick={joinByCode}
          />
        }
      >
        <div className="rooms__modal-inputs">
          <AppInput
            value={roomCode}
            placeholder={t('rooms.code.enter_code')}
            type="number"
            maxLength={6}
            backgroundColor={COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0.8)}
            onChangeText={setRoomCode}
          />
        </div>
      </Modal>
      <Modal
        isVisible={createRoomModal}
        title={t('rooms.create.create_room')}
        closeModal={toggleCreateRoomModal}
        successButton={
          <AppButton
            text={t('rooms.create.join_btn')}
            textColor={COLORS.YELLOW}
            marginRight={16}
            backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, createDisabled ? 0.3 : 0.6)}
            hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_8, 0.9)}
            isDisabled={createDisabled}
            onClick={createRoomAndJoin}
          />
        }
      >
        <div className="rooms__modal-inputs">
          <AppInput
            value={roomName}
            placeholder={t('rooms.create.enter_name')}
            maxLength={18}
            backgroundColor={COLORS.convertHEXtoRGBA(COLORS.BLACK_1, 0.8)}
            onChangeText={setRoomName}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
