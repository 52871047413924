import { ComponentProps, memo, useCallback, useState } from 'react';
import { IRoomWithInfoAndTask } from '@pages/rooms/interfaces';
import { COLORS, EAuthMessage } from '@scrum/shared-lib';
import { ClickableIcon } from '@components/presseble-icon/ClickableIcon';
import { DropDownMenu } from '@components/drop-menu/DropDownMenu';
import { AppButton } from '@components/app-button/AppButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Leader } from '@components/svg/Leader';
import { ApiService } from '@services/AppApi';
import { useTypedRxSelector } from '@hooks/typed-rx-selector';
import { Modal } from '@components/modal/Modal';
import './styles.sass';

interface IProps extends IRoomWithInfoAndTask {
  currentUserId: string;
  openRoomInfoModal: (roomId: string) => void;
  removeRoomFromList: (id: string) => void;
}

export const RoomCard = memo(
  ({
    name,
    registrationCode,
    id,
    currentUserId,
    createdUserId,
    userInfo,
    lastTask,
    removeRoomFromList,
    openRoomInfoModal,
  }: IProps) => {
    const toastService = useTypedRxSelector(({ common }) => common.toastService);
    const [dropIsVisible, setDropIsVisible] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const toggleInfoVisible = useCallback(() => {
      setInfoVisible(prev => !prev);
    }, []);

    const toggleDropDownVisible = useCallback(() => {
      setDropIsVisible(prev => !prev);
    }, []);

    const copyCode = useCallback(() => {
      navigator.clipboard.writeText(registrationCode);
      toastService.show({ text: t('toast.info.copy_code') });
    }, [registrationCode]);

    const copyLink = useCallback(() => {
      navigator.clipboard.writeText(process.env.REACT_APP_BASE_DOMAIN + `/room/${id}`);
      toastService.show({ text: t('toast.info.copy_link') });
    }, [id]);

    const join = () => {
      navigate(`/room/${id}`, { state: { userId: currentUserId } });
    };

    const openInfo = useCallback(() => {
      openRoomInfoModal(id);
    }, [id]);

    const handleRemove = useCallback(async () => {
      if (userInfo.role === 'leading') {
        const response = await ApiService.removeRoom(id);

        if (response.message === EAuthMessage.ROOM_REMOVED) {
          toastService.show({ text: t('toast.info.remove_room') });
          removeRoomFromList(response.roomId);
        } else {
          toastService.show({ text: response.message, type: 'error' });
        }
      } else {
        const response = await ApiService.unsubscribeRoom(id);

        if (response.message === EAuthMessage.ROOM_UNSUBSCRIBED) {
          toastService.show({ text: t('toast.info.unsub_room') });
          removeRoomFromList(response.roomId);
        } else if (response.message === EAuthMessage.ROOM_NOT_FOUND) {
          toastService.show({ text: response.message, type: 'error' });
        }
      }
    }, [userInfo.role, id, toastService]);

    return (
      <div
        className="room-item"
        style={{
          border:
            currentUserId == createdUserId
              ? `1px solid ${COLORS.convertHEXtoRGBA(COLORS.YELLOW, 0.2)}`
              : undefined,
        }}
      >
        <div
          className="room-item__background"
          style={{ backgroundColor: COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.6) }}
        />
        <div className="room-item__info">
          <div className="room-item__statuses">
            {userInfo.role === 'leading' && (
              <div className="room-item__leading">
                <Leader />
              </div>
            )}
            <span className="room-item__title">{name}</span>
          </div>
          <span className="room-item__description">
            {lastTask?.title || t('rooms.room.empty_task')}
          </span>
        </div>
        <div className="room-item__buttons">
          <ClickableIcon
            icon="LogIn"
            size={35}
            iconColor={COLORS.WHITE}
            marginRight={8}
            onClick={join}
          />
          <DropDownMenu
            isVisible={dropIsVisible}
            closeMenu={toggleDropDownVisible}
            button={<ClickableIcon icon="MoreVertical" size={35} onClick={toggleDropDownVisible} />}
          >
            <MenuItem
              icon="AlignCenter"
              text={t('room.menu.mote_info')}
              iconColor={COLORS.GRAY_6}
              onClick={openInfo}
            />
            <MenuItem
              icon="Copy"
              text={t('room.menu.copy_code')}
              iconColor={COLORS.GRAY_6}
              onClick={copyCode}
            />
            <MenuItem
              icon="Link"
              text={t('room.menu.copy_link')}
              iconColor={COLORS.GRAY_6}
              onClick={copyLink}
            />
            <MenuItem
              icon="Trash"
              text={t(userInfo.role === 'leading' ? 'room.menu.remove' : 'room.menu.unsubscribe')}
              iconColor={COLORS.RED}
              onClick={handleRemove}
            />
          </DropDownMenu>
        </div>
        <Modal isVisible={infoVisible} title="123" closeModal={toggleInfoVisible} />
      </div>
    );
  },
);

const MenuItem = ({
  text,
  icon,
  iconColor,
  onClick,
}: Pick<ComponentProps<typeof AppButton>, 'text' | 'iconColor' | 'onClick' | 'icon'>) => {
  return (
    <AppButton
      reverse
      width="230px"
      borderRadius={0}
      paddingHorizontal={20}
      textColor={iconColor}
      hoverColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_9, 0.7)}
      backgroundColor={COLORS.convertHEXtoRGBA(COLORS.GRAY_10, 0.8)}
      {...{ text, icon, iconColor, onClick }}
    />
  );
};
