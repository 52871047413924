import { Component, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { SocketService } from '@services/AppSocket';
import { Content } from './Content';
import { Settings } from './settings/Settings';
import { RoomHeader } from '@pages/room/header/RoomHeader';
import { MainLoader } from '@components/main-loader/MainLoader';
import { ROOM_ID_KEY } from '@constants/local-storage';
import { useTypedSelector } from '@hooks/typed-selector';
import './styles.sass';

interface IProps {
  roomId: string;
  userId: string;
}

interface IState {
  isLoading: boolean;
  settingsOpened: boolean;
  dropIsOpened: boolean;
}

class RoomPageComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isLoading: true,
      settingsOpened: false,
      dropIsOpened: false,
    };
  }

  async componentDidMount() {
    const { userId, roomId } = this.props;

    await SocketService.connect(roomId, userId);
    localStorage.setItem(ROOM_ID_KEY, roomId);
    this.setState({ isLoading: false });
  }

  public componentWillUnmount() {
    SocketService.close();
    localStorage.removeItem(ROOM_ID_KEY);
  }

  private toggleSettingsVisible = () => {
    this.setState(prev => ({ settingsOpened: !prev.settingsOpened }));
  };

  private closeSettings = () => {
    this.setState({ settingsOpened: false });
  };

  render() {
    const { isLoading, settingsOpened } = this.state;
    const { closeSettings } = this;

    return (
      <Fragment>
        <RoomHeader openSettings={this.toggleSettingsVisible} />
        <Settings isOpened={settingsOpened} {...{ closeSettings }} />
        {isLoading ? <MainLoader /> : <Content />}
      </Fragment>
    );
  }
}

export const RoomPage = () => {
  const { id } = useParams();
  const currentUserId = useTypedSelector(({ user }) => user?.currentUser?.id || '');

  return <>{!!currentUserId && <RoomPageComponent roomId={id || ''} userId={currentUserId} />}</>;
};
